import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import imgMatriz from "../img/Sucursales/matrizLaHuerta.webp";
import imgsucursal1 from "../img/Sucursales/sucursalLaconcha.webp";
import imgsucursal2 from "../img/Sucursales/sucursalSanMateo.webp";
import imgSucursal3 from "../img/Sucursales/sucursalCasimiro.webp";
import imgSucursal4 from "../img/Sucursales/sucursalLoarado.webp";
import imgSucursal5 from "../img/Sucursales/sucursalVilla.webp";
import imgSucursal6 from "../img/Sucursales/sucursalBarra.webp";
import imgSucursal7 from "../img/Sucursales/sucursalCihuatlan.webp";
import imgSucursal8 from "../img/Sucursales/SucursalLaManzanilla.webp";
import imgSucursal9 from "../img/Sucursales/SucursalMiguelHidalgo.webp";
import imgSucursal10 from "../img/Sucursales/sucursalMorelos.webp";
import { Grow, Typography } from "@mui/material";
import cImgMancha from "../img/Mancha.webp";

const cImages = [
  { src: imgMatriz, title: "La Huerta" },
  { src: imgsucursal2, title: "San Mateo" },
  { src: imgsucursal1, title: "La Concepción" },
  { src: imgSucursal3, title: "Casimiro Castillo" },
  { src: imgSucursal4, title: "Lo Arado" },
  { src: imgSucursal5, title: "Villa Purificación" },
  { src: imgSucursal6, title: "Barra de Navidad" },
  { src: imgSucursal7, title: "Cihuatlán" },
  { src: imgSucursal8, title: "La Manzanilla" },
  { src: imgSucursal9, title: "Miguel Hidalgo" },
  { src: imgSucursal10, title: "José Ma. Morelos" },
];

const CNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="c-slick-arrow c-next-arrow"></div>
    </div>
  );
};

const CPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="c-slick-arrow c-prev-arrow"></div>
    </div>
  );
};

const MyCarousel = () => {
  const [checked, setChecked] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CNextArrow />,
    prevArrow: <CPrevArrow />,
    appendDots: (dots) => <ul style={{ margin: "0" }}>{dots}</ul>,
    customPaging: () => <div className="c-slick-dot"></div>,
    beforeChange: (current, next) => setChecked(false),
    afterChange: (current) => setChecked(true),
  };

  return (
    <div className="c-carousel-container">
      <Slider {...settings}>
        {cImages.map((image, index) => (
          <div key={index} className="c-carousel-slide">
            <img src={image.src} alt="" className="c-carousel-image" />
            <img
              src={cImgMancha}
              style={{ filter: "brightness(0) invert(1)" }}
              alt=""
              className="c-carousel-mancha"
            />
            <Grow in={checked}>
              <Typography
                color={"#0C213D"}
                className="c-carousel-title"
                sx={{
                  fontSize: { xs: ".84rem", sm: "1.3rem", md: "1.5rem" },
                  fontWeight: "lighter",
                }}
              >
                {image.title}
              </Typography>
            </Grow>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default MyCarousel;

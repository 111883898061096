import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const FormParts = () => {
  const form = useRef();
  const [isVerified, setIsVerified] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!isVerified) {
      alert("Por favor, completa el ReCAPTCHA antes de enviar.");
      return;
    }
    
    const { default: Swal } = await import('sweetalert2');
    const MySwal = await import('sweetalert2-react-content').then(module => module.default(Swal));

    emailjs
      .sendForm("service_ovybipj", "template_93a9q1d", form.current, "E077c2KKLaXkoguHr")
      .then(
        () => {
          form.current.reset();
          setIsVerified(false); // Restablece el estado de verificación
          MySwal.fire({
            title: '¡Éxito!',
            text: 'Mensaje enviado',
            icon: 'success'
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const onRecaptchaChange = (value) => {
    setIsVerified(!!value);
  };

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ mb: "1rem" }}>
        Preguntas o comentarios
      </Typography>
      <Typography variant="h3" color={"#1976D2"} gutterBottom sx={{ mb: "2rem" }}>
        ¡Comunícate!
      </Typography>
      <form ref={form} onSubmit={sendEmail}>
        <Grid container spacing={2}>
          {/* Campos del formulario */}
          <Grid item xs={12} sm={6} sx={{ mb: "1rem" }}>
            <TextField
              label="Nombre completo"
              id="user_name"
              name="user_name"
              inputProps={{ maxLength: 70 }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Teléfono"
              name="user_phone"
              inputProps={{ maxLength: 10, inputMode: "numeric" }}
              onInput={(event) => {
                event.target.value = event.target.value.replace(/[^0-9]/g, "");
              }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: "1rem" }}>
            <TextField
              label="Correo electrónico"
              name="user_email"
              type="email"
              inputProps={{ maxLength: 200 }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Comentario"
              multiline
              rows={4}
              name="message"
              inputProps={{ maxLength: 400 }}
              required
              fullWidth
              sx={{ mb: "1rem" }}
            />
          </Grid>

          {/* ReCAPTCHA */}
          <Grid item xs={12}>
            <ReCAPTCHA
              sitekey="6Ldrz3IoAAAAAMZKHAFuGKSwlDUs8Xl6JifW56-h"
              onChange={onRecaptchaChange}
            />
          </Grid>

          {/* Botón de enviar */}
          <Grid item xs={12} sx={{ mb: "2rem" }}>
            <Button
              variant="contained"
              color="primary"
              title="Send"
              size="large"
              type="submit"
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormParts;

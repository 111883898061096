import React from "react";
import {
  Box,
  Container,
  Tabs,
  Typography,
  Grid,
  keyframes,
} from "@mui/material";
import imgRibbon from "../../img/Services/Cajero/ImgRibbonCajero.png";
import Tab from "@mui/material/Tab";
import PeopleOutlineIcon from "@mui/icons-material/AccountBalance";
import SentimentVerySatisfiedIcon from "@mui/icons-material/FmdGood";
import SliderCajeros from "./SliderCajeros.jsx";
import MapCajeros from "./MapCajeros.jsx";
//Boton para subir la pantalla
import ButtonArrow from "../ButtonArrow";
import GroupButton from "../GroupButtons.jsx";

//Para hacer las pulsaciones del titulo principal
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

function TabPanel({ value, index, children }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Cajeros() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <ButtonArrow />
      <GroupButton />
      {/*Ribbon Start*/}
      <Box
        sx={{
          my: 4,
          backgroundColor: "#0C213D",
          display: "flex",
          flexDirection: "column", // Añade esto
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "6cm", // Añade esto
        }}
      >
        <img
          src={imgRibbon}
          alt="Ribbon"
          style={{
            width: "100%",
            height: "100%", // Cambia esto
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          color={"white"}
          sx={{
            fontSize: { xs: "2.8rem", sm: "3.8rem", md: "3.8rem" },
            animation: `${pulse} 2s infinite`,
          }}
        >
          CAJEROS AUTOMÁTICOS
        </Typography>
      </Box>

      {/*Socios tabs start*/}
      <Box sx={{ width: "100%" }} mb={"3rem"}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Tab partners"
            centered
          >
            <Tab
              icon={<PeopleOutlineIcon />}
              label="Cajeros"
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  marginRight: 1,
                },
              }}
            />
            <Tab
              icon={<SentimentVerySatisfiedIcon />}
              label="Mapa de nuestros cajeros"
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  marginRight: 1,
                },
              }}
            />
          </Tabs>
        </Box>

        {/*Contend tabs start*/}
        <TabPanel value={value} index={0}>
          <Typography
            variant="h6"
            paragraph
            sx={{ textAlign: "justify", mx: "2rem" }}
          >
            Ponemos a tu disposición 6 cajeros automáticos en RED ubicados en
            las sucursales de La Huerta, Villa Purificación, San Mateo, La
            Manzanilla y José Ma. Morelos. Servicio disponible las 24 horas del día.
          </Typography>
          <Grid container justifyContent="center" mb={23}>
          <SliderCajeros />
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"#FB6823"}
            sx={{
              top: { xs: "32%", sm: "32%", lg: "37%" },
              left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
            }}
          >
            ¡Visita nuestros cajeros ATM!
          </Typography>
          <Typography
            variant="h6"
            paragraph
            sx={{ textAlign: "justify", mx: "2rem" }}
          >
            Para tu comodidad te brindamos información sobre las ubicaciones de
            nuestras sucursales con servicios de cajeros automáticos.
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={9}>
              <MapCajeros />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Container>
  );
}
export default Cajeros;

import {
  Box,
  Card,
  CardMedia,
  Container,
  Fade,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import AhorroOrdinario from "../img/Saved/ahorroOrdinario.png";
import ahorroJuvenil from "../img/Saved/ahorroJuvenil.png";
import soliTarjetaDorada from "../img/Saved/solitarjetaDorada.png";
import soliProductivo from "../img/Saved/soliProductivo.png";
import soliPersonal from "../img/Saved/soliPersonal.png";
import soliVivienda from "../img/Saved/soliVivienda.png";
import soliExpress from "../img/Saved/SoliExpress.png";
import soliHipotecario from "../img/Saved/soliHipotecario.png"
import soliAuto from "../img/Saved/soliAuto.png";
import soliAutomatico from "../img/Saved/soliautomatico.png"   
import tarjetaDebito from "../img/Saved/tarjetaDebito.png"; 
import creditoAlaPalabra from "../img/Saved/creditoalapalabra.png" 
import ahorroPromocional from "../img/Saved/ahorroPromocional.png"; 
import inversionPlazos from "../img/Saved/inversionPlazos.png";

import { useNavigate, useLocation } from "react-router-dom";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
//Boton para subir la pantalla
import ButtonArrow from "./ButtonArrow";
import GroupButton from "./GroupButtons";

const PlanType = styled(Typography)({
  position: "relative",
  "&:hover": {
    color: "orange",
  },
  "&:hover::after": {
    //add new Features hover
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: "orange",
  },
});

export const savingsPlans = [
  {
    id: `1`,
    name: "CREDITO A LA PALABRA",
    type: "CREDITO",
    image: creditoAlaPalabra,
    description:
      "Exclusivo para socios fundadores con aportaciones a credito a la palabra",
    TasaOrdinaria: "18.00% Anual Fija",
    TasaMoratoria: "48.00% Anual Fija",
    MontoMinimo: "$ 500.00",
    Montomaximo: "$ 14,900.00",
    PlazoMaximo: "18 meses",
    FormasDepago:
      "Pago unico de capital e intereses (maximo 18 meses) y pagos periodicos de intereses y capital",
    BaseDeOtorgamiento: "No requiere de garantía Liquida",
    Avales: "Sin avales",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    Documentacion: "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial", 
    Cat: "19.57%",
    PagoVencimiento: "Para pagos a un vencimiento hasta 18 meses",
    Comisiones: "Este producto no genera comisiones"
  },
  {
    id: `2`,
    name: "SOLI-TARJETA DORADA",
    type: "CREDITO",
    image: soliTarjetaDorada,
    description:
      "Para socios con excelente historial crediticio, plenamente identificados y autorizados por el Comité de Crédito",
    TasaOrdinaria: "22.00% Anual Fija",
    TasaMoratoria: "48.00% Anual Fija",
    MontoMinimo: "$ 500.00",
    Montomaximo: "$ 250,000.00",
    PlazoMaximo: "96 meses",
    FormasDepago:
      "Pago unico de capital e intereses (maximo 18 meses) y pagos periodicos de intereses y capital",
    BaseDeOtorgamiento: "Garantía liquida del 10%",
    Avales: "Sin avales",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    Documentacion: "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial", 
    Cat: "24.37%",
    PagoVencimiento: "Para pagos a un vencimiento hasta 18 meses",
    Comisiones: "Este producto no genera comisiones"
  },
  {
    id: `3`,
    name: "SOLI-PRODUCTIVO",
    type: "CREDITO",
    image: soliProductivo,
    description:
      "Créditos destinados para la agricultura, ganadería y el comercio",
    TasaOrdinaria: "20.00% Anual Fija",
    TasaMoratoria: "48.00% Anual Fija",
    MontoMinimo: "$ 500.00",
    Montomaximo: "$ 400,000.00",
    PlazoMaximo: "96 Mensualidades",
    FormasDepago:
      "Pago unico de capital e intereses (maximo 18 meses), pagos periodicos de intereses y capital",
    BaseDeOtorgamiento: "Se dejara en garantía liquida el 10%",
    Avales:
      "De 1 a 2 obligados solidarios (Avales)",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "Si requiere de garantía Liquida",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    TipoGarantia: "Si requiere garantía quirografaria",
    Documentacion: "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "21.95%",
    PagoVencimiento: "Para pagos a un vencimiento hasta 18 meses",
    Comisiones: "Este producto no genera comisiones"
  },
  {
    id: `4`,
    name: "SOLI-PERSONAL",
    type: "CREDITO",
    image: soliPersonal,
    description:
      "Créditos destinados para gastos personales",
    TasaOrdinaria: "24.00% Anual Fija",
    TasaMoratoria: "48.00% Anual Fija",
    MontoMinimo: "$ 500.00",
    Montomaximo: "$ 400,000.00",
    PlazoMaximo: "96 Mensualidades",
    FormasDepago:
      "Pago único de capital e intereses (máximo 18 meses), pagos periódicos de intereses y capital",
    BaseDeOtorgamiento: "Se dejara el 10% en garantia liquida",
    Avales:
      "De 1 a 2 obligados solidarios (Avales)",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "No requiere de garantía Liquida",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    TipoGarantia: "Si requiere garantía quirografaria",
    Documentacion: "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "26.84%",
    PagoVencimiento: "Para pagos a un vencimiento hasta 18 meses",
    Comisiones: "Este producto no genera comisiones"
  },
  {
    id: `5`,
    name: "SOLI-VIVIENDA",
    type: "CREDITO",
    image: soliVivienda,
    description:
      "Destinados a créditos para la vivienda, como lo son las remodelaciones, mejoras, adquisición, etc",
    TasaOrdinaria: "21.60% Anual Fija",
    TasaMoratoria: "48.00% Anual Fija",
    MontoMinimo: "$ 500.00",
    Montomaximo: "$ 400,000.00",
    PlazoMaximo: "96 Mensualidades",
    FormasDepago:
      "Pago único de capital e intereses (máximo 18 meses), pagos periódicos de intereses y capital",
    BaseDeOtorgamiento: "Se dejara el 10% en garantía liquida",
    Avales:
      "De 1 a 2 obligados solidarios (Avales).",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "Si requiere de garantía Liquida",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    TipoGarantia: "Si requiere garantía quirografaria",
    Documentacion: "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "23.89%",
    PagoVencimiento: "Para pagos a un vencimiento hasta 18 meses",
    Comisiones: "Este producto no genera comisiones"
  },
  {
    id: `6`,
    name: "SOLI-EXPRESS",
    type: "CREDITO",
    image: soliExpress,
    description:
      "Crédito de entrega inmediata para cualquier necesidad de financiamiento",
    TasaOrdinaria: "36.00% Anual Fija",
    TasaMoratoria: "48.00% Anual Fija",
    MontoMinimo: "$ 500.00",
    Montomaximo: "$ 100,000.00",
    PlazoMaximo: "60 Mensualidades",
    FormasDepago: "Pagos fijos de capital e intereses",
    BaseDeOtorgamiento:
      "No requiere garantía liquida, pero si requiere una verificación al domicilio y/o negocio",
    Avales: "Sin avales",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "No requiere de garantía Liquida",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    TipoGarantia: "No requiere garantía quirografaria",
    Documentacion: "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "42.76%",
    PagoVencimiento: "El pago al vencimiento no aplica en este credito",
    Comisiones: "Este producto no genera comisiones"
  },
  {
    id: `7`,
    name: "SOLI-HIPOTECARIO",
    type: "CREDITO",
    image: soliHipotecario,
    description:
      "Destinados para solventar cualquier necesidad de financiamiento dejando una garantía hipotecaria a favor de la cooperativa",
    TasaOrdinaria: "18.00% Anual Fija",
    TasaMoratoria: "48.00% Anual Fija",
    MontoMinimo: "$ 300,000.00",
    Montomaximo: "$ 1,000,000.00",
    PlazoMaximo: "96 Mensualidades",
    FormasDepago:
      "Pago único de capital e intereses (máximo 18 meses), pagos periódicos de intereses y capital",
    BaseDeOtorgamiento: "5% de garantía liquida",
    Avales:
      "Aval solo el cónyuge del solicitante o en caso de que exista un garante hipotecario seria también su cónyugue",
    GarantiaHipotecaria: "Valor comercial mínimo 3:1",
    GarantiasLiquidas: "Si requiere de garantía Liquida",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    TipoGarantia: "No requiere garantía quirografaria",
    Documentacion: "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial, Avaluo Bancario y certificado de libertad de gravamen de la propiedad a hipotecar",
    Cat: "19.57%",
    PagoVencimiento: "Para pagos a un vencimiento hasta 18 meses",
    Comisiones: "Este producto no genera comisiones"
  },
  {
    id: `8`,
    name: "SOLI-AUTO",
    type: "CREDITO",
    image: soliAuto,
    description:
      "Exclusivo para la compra de vehículos nuevos (automóviles y motocicletas). Te podemos financiar máximo el 80% del valor factura en caso de automóvil y el 100% en motocicletas",
    TasaOrdinaria: "12.00% Anual Fija",
    TasaMoratoria: "48.00% Anual Fija",
    MontoMinimo: "$ 5,000.00",
    Montomaximo: "$ 600,000.00",
    PlazoMaximo: "72 Mensualidades",
    FormasDepago: "Pago periódicos de capital e intereses",
    BaseDeOtorgamiento: "No requiere garantía liquida",
    Avales:
      "Es necesario un aval en el caso de la adquisición de motocicletas, para los automóviles solo en  caso de que este casado agregar al conyuge",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "No requiere de garantía Liquida",
    GarantiasPrendarias: "Factura del vehículo endosado a favor de la Caja",
    TipoGarantia: "Si requiere garantía quirografaria",
    Documentacion: "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial, cotización de la unidad",
    Cat: "12.69%",
    PagoVencimiento: "El pago al vencimiento no aplica en este credito",
    Comisiones: "Este producto no genera comisiones"

  },
  {
    id: `9`,
    name: "SOLI-AUTOMATICO",
    type: "CREDITO",
    image: soliAutomatico,
    description:
      "Te podemos financiar en base a tus ahorros",
    TasaOrdinaria: "14.40% Anual Fija",
    TasaMoratoria: "48.00% Anual Fija",
    MontoMinimo: "$ 500.00",
    Montomaximo: "$ 10,000.00 UDIS",
    PlazoMaximo: "24 Mensualidades",
    FormasDepago:
      "Pago único de capital e intereses (máximo 18 meses), pagos periódicos de intereses y capital",
    BaseDeOtorgamiento:
      "Se otorgara el 90% de tus haberes si el plazo máximo es de 12 meses o hasta 18 meses siempre y cuando sea en pagos periódicos. de lo contrario el 80% de tus haberes si el plazo es a un solo vencimiento mayor a 12 meses o hasta 24 meses",
    Avales: "Sin avales",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "Si requiere de garantía Liquida",
    GarantiasPrendarias: "No aplican las garantías prendarias",
    TipoGarantia: "Si requiere garantía quirografaria",
    Documentacion: "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "15.40%",
    PagoVencimiento: "Para pagos a un vencimiento hasta 18 meses",
    Comisiones: "Este producto no genera comisiones"
  },
  //aqui dan inicio los ahorros
  {
    id: `10`,
    name: "AHORRO ORDINARIO",
    type: "AHORRO",
    image: AhorroOrdinario,
    TipoProducto: "Deposito a la vista",
    SaldoMinimo: "No es necesario un saldo minimo",
    TasaInteres: "1.50%",
    MontoApertura: "Sin monto mínimo de apertura",
    FechaVigencia: "Vigente",
    GatNominal: "1.51%",
    GatReal: "-4.07%",
    Comisiones: "Este producto no genera comisiones",
    TasaInteresAnual: " 1.5% "
  },
  {
    id: `11`,
    name: "TARJETA DE DEBITO",
    type: "AHORRO",
    image: tarjetaDebito,
    TipoProducto: "Deposito a la vista,",
    TipoProducto2: " donde el socio podrá disponer de su dinero mediante el uso de la Tarjeta de Débito CSLH",
    SaldoMinimo: "No es necesario un saldo minimo",
    TasaInteres: "0.00%",
    MontoApertura: "Sin monto mínimo de apertura",
    Requisito1: "Ser socio de Caja Solidaria La Huerta",
    Requisito2: "Cubrir costo de anualidad $ 250.00 (Doscientos cincuenta pesos 00/100 MN) IVA incluído",
    Requisito3: "En caso de reposición de la Tarjeta de Débito tendrá un costo de $150 (ciento cincuenta pesos 00/10 MN) IVA incluído",
    FechaVigencia: "Vigente",
    Beneficio1: "Disponer de tu dinero las 24 horas del día, sin costo alguno en cualquier cajero automático de Caja Solidaria La Huerta",
    Beneficio2: "Pagar tus compras o consumo en cualquier establecimiento que cuente con terminal bancaria",
    Beneficio3: "Retirar dinero en efectivo en cualquier establecimiento que cuente con servicio de Cash back",
    Beneficio4: "Compra de tiempo aire para tu celular",
    GatNominal: "0.00%",
    GatReal: "-5.57%",
    Comisiones: "Este producto si genera comisiones",
  },
  {
    id: `12`,
    name: "AHORRO JUVENIL",
    type: "AHORRO",
    image: ahorroJuvenil,
    TipoProducto: "Deposito a la vista,",
    TipoProducto2: " exclusivo para ahorradores menores de edad",
    SaldoMinimo: "No es necesario un saldo minimo",
    TasaInteres: "3.00%",
    MontoApertura: "Un monto minimo de apertura de $10.00 (Diez pesos 00/100 MN)",
    FechaVigencia: "Vigente",
    GatNominal: "3.04%",
    GatReal: "-2.57%",
    Comisiones: "Este producto no genera comisiones",
    TasaInteresAnual: " 3% "
  },
  {
    id: `14`,
    name: "AHORRO PROMOCIONAL",
    type: "AHORRO",
    image: ahorroPromocional,
    TipoProducto: "Deposito a la vista,",
    TipoProducto2: " exclusivo para participar en las diferentes promociones ofertadas durante el año",
    SaldoMinimo: "No es necesario un saldo minimo",
    TasaInteres: "0.00%",
    MontoApertura: "Sin monto mínimo de apertura",
    FechaVigencia: "Vigente",
    GatNominal: "0.00%",
    GatReal: "-5.57%",
    Comisiones: "Este producto no genera comisiones",
  },
  //Aqui inicia el apartado inversion
  {
    id: `15`,
    name: "PLAZO FIJO",
    type: "INVERSION",
    image: inversionPlazos,
    TasaInteres: "4.00%",
    MontoAper: "$5,000",
    Tiempo: "30 Días",
    DescribirMontos: "Todos los montos",
    FechaVigencia: "Vigente",
    descripcionProducto: "La inversión a Plazo Fijo es un producto utilizado por los socios que buscan generar mayores rendimientos",
    TablaNombre: "Tabla de productos de Plazo Fijo",
    Comisiones: "Este producto no genera comisiones",
  },
];

export default function Products() {
  //Var filter products
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");

  const [filteredPlans, setFilteredPlans] = useState(savingsPlans);

  useEffect(() => {
    if (category === "all") {
      setFilteredPlans(savingsPlans);
    } else {
      setFilteredPlans(savingsPlans.filter((plan) => plan.type === category));
    }
  }, [category]);
  //var filter products

  const navigate = useNavigate();

  const handleCardClick = (plan) => {
    navigate(`/detailsProduct/${plan.type}/${plan.id}`);
  };

  return (
    <Container maxWidth="lg">
      <ButtonArrow />
      <GroupButton/>
      <Grid container spacing={7} sx={{ my: 2 }} justifyContent="center">
        {filteredPlans.map((plan) => (
          <Grid item xs={11} sm={6} md={4} key={plan.id} sx={{ mb: 4 }}>
            <Fade in timeout={3000}>
              <Card
                sx={{
                  position: "relative",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() => handleCardClick(plan)}
              >
                <CardMedia
                  component="img"
                  height="325"
                  image={plan.image}
                  alt={plan.name}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgcolor: "#0C213D",
                    opacity: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "opacity 0.3s",
                    "&:hover": {
                      opacity: 0.9,
                    },
                  }}
                >
                  <Box>
                    <CurrencyExchangeOutlinedIcon
                      sx={{ fontSize: "57px", opacity: 0.6 }}
                      htmlColor="white"
                    />
                  </Box>
                  <Box>
                    <Typography variant="h5" color="white">
                      {plan.name}
                    </Typography>
                  </Box>
                  <Box>
                    <PlanType variant="body" color="orange">
                      {plan.DescribirMontos}
                    </PlanType>
                  </Box>
                  <Box>
                    <PlanType variant="body" color="white">
                      {plan.type}
                    </PlanType>
                  </Box>
                </Box>
              </Card>
            </Fade>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import ImgCajero1 from "../../img/Services/Cajero/CajeroLaHuerta.webp";
import ImgCajero2 from "../../img/Services/Cajero/cajeros_Matriz.webp";
import ImgCajero3 from "../../img/Services/Cajero/CajerosLaHuerta.webp";

const images = [
  { src: ImgCajero1 },
  { src: ImgCajero2 },
  { src: ImgCajero3 },
];

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="slick-arrow next-arrow"></div>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="slick-arrow prev-arrow"></div>
    </div>
  );
};

const MyCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => <ul style={{ margin: "0" }}>{dots}</ul>,
    customPaging: () => <div className="slick-dot"></div>,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box key={index} className="carousel-slide">
            <img src={image.src} alt="" className="carousel-image" />
            <Box className="carousel-buttons">
              {/* Flechas centradas dentro de las imágenes */}
              <NextArrow />
              <PrevArrow />
            </Box>
          </Box>
        ))}
      </Slider>
      <style>
        {`
          .carousel-container {
            margin-top: 20px;
            width: 470px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 15px;
            position: relative;
            margin-bottom: 20px;
          }

          /* Estilos del carrusel */
          .slick-arrow {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            opacity: 1; /* Ajusta la opacidad */
            z-index: 1;
            box-shadow: none; /* Elimina la sombra */
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 35px;
            color: #fff;
            display: none; /* Oculta las flechas en pantallas pequeñas */
          }

          .next-arrow {
            right: 0;
          }

          .prev-arrow {
            left: 0;
          }

          .slick-dots {
            bottom: -25px;
            display: flex;
            justify-content: center; /* Centra los puntos indicadores */
          }

          .slick-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin: 0 5px;
            opacity: 1; /* Ajusta la opacidad */
            background-color: #0C213D; /* Cambia el color a azul marino */
          }

          .slick-active .slick-dot {
            background-color: #1976D2; /* Color naranja oscuro */
          }

          .carousel-slide {
            outline: none;
            display: flex;
            justify-content: center; /* Centra las imágenes */
          }

          .carousel-image {
            max-width: 100%;
            max-height: 100%;
            border-radius: 15px;
          }

          @media screen and (max-width: 600px) {
            .carousel-container {
              margin-top: 8px;
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }

            /* Ajusta el tamaño de las flechas en pantallas pequeñas */
            .slick-arrow {
              display: block; /* Muestra las flechas en pantallas grandes */
              font-size: 25px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default MyCarousel;

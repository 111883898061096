import React, { useEffect } from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import { keyframes, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import styled from "@emotion/styled";

//Animation marker map
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const TopBar = () => {
  //Funcion encargada de hacer que el telefono se vea como si estuviera timbrando
  const RingAnimation = styled("div")`
    animation: ring 2s ease-in-out infinite;

    @keyframes ring {
      0% {
        transform: rotate(0deg);
      }
      10% {
        transform: rotate(30deg);
      }
      20% {
        transform: rotate(-20deg);
      }
      30% {
        transform: rotate(20deg);
      }
      40% {
        transform: rotate(-10deg);
      }
      50% {
        transform: rotate(10deg);
      }
      60% {
        transform: rotate(-5deg);
      }
      70% {
        transform: rotate(5deg);
      }
      80% {
        transform: rotate(-2deg);
      }
      90% {
        transform: rotate(2deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  `;

  //para hacer que el icono de correo electronico gire en su propio eje
  const [rotate, setRotate] = React.useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRotate((prevRotate) => prevRotate + 360);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (isSmallScreen) return null;
  const linkStyle = {
    textDecoration: "none",
  };
  return (
    <AppBar position="static" sx={{ zIndex: 3, backgroundColor: "#0c213d" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Elemento izquierdo */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RingAnimation>
            <PhoneIcon className="icon" sx={{ fontSize: "2rem", mr: 1 }} />
          </RingAnimation>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body2" component="div">
              Contáctanos
            </Typography>
            <a href={`tel:${"357 384 0480"}`} style={linkStyle}>
              <Typography
                variant="body1"
                sx={{ color: "#fff", "&:hover": { color: "orange" } }}
              >
                357 384 0480
              </Typography>
            </a>
          </Box>
        </Box>
        {/* Elemento en el centro */}
        <Box
          sx={{ display: "flex", flexDirection: "flex", alignItems: "center" }}
        >
          <EmailIcon
            className="icon"
            sx={{
              fontSize: "2rem",
              marginBottom: "1px",
              transition: "transform 0.5s",
              transform: `rotateY(${rotate}deg)`,
            }}
          />
          <Box sx={{ display: "column", flexDirection: "row" }}>
            <Typography
              variant="body1"
              sx={{ marginRight: 1, marginLeft: "-4px" }}
            >
              Correo
            </Typography>
            <Typography variant="body1">gerencia@cajasolidaria.net</Typography>
          </Box>
        </Box>

        {/* Elemento derecho */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LocationOnIcon
            className="icon"
            sx={{ fontSize: "2rem", mr: 1, animation: `${pulse} 2s infinite` }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1">Domicilio</Typography>
            <Typography variant="body1">
              Emiliano Zapata 24, Centro, 48850 La Huerta, Jal.
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

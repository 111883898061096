import React from "react";
import Dialog from "@mui/material/Dialog";
import PDFUNE from "../Documents/UNE.pdf";
export default function UNE({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <embed 
        src={PDFUNE}
        type="application/pdf"
        style={{ width: '100%', height: '100vh' }}
      />
    </Dialog>
  );
}

import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import Confianza from "../../img/Services/Remesas/Confianza.webp";
import Airpak from "../../img/Services/Remesas/Airpak.webp";
import Intermex from "../../img/Services/Remesas/Intermex.webp";
import Modal from "./Modal/Modal.jsx";

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="slick-arrow next-arrow"></div>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="slick-arrow prev-arrow"></div>
    </div>
  );
};
const SliderRemesas = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [
    { id: 1, image: Intermex },
    { id: 2, image: Airpak },
    { id: 3, image: Confianza },
  ];

  const handleImageClick = (id) => {
    setSelectedImageId(id);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedImageId(null);
  };

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + images.length) % images.length
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    dots: true,
    autoplaySpeed: 4000,
    // ... (configuración del slider)
    customPaging: () => <div className="c-slick-dot"></div>,
    nextArrow: <NextArrow onClick={handleNextSlide} />,
    prevArrow: <PrevArrow onClick={handlePrevSlide} />,
    beforeChange: (oldIndex, newIndex) => handleSlideChange(newIndex),
    // ... (resto de la configuración)
  };

  const renderCarouselSlide = (imageObj) => (
    <Box
      className="carousel-slide"
      key={imageObj.id}
      onClick={() => handleImageClick(imageObj.id)}
    >
      <img src={imageObj.image} alt="" className="carousel-image" />
    </Box>
  );

  return (
    <div className="carousel-container">
      <Slider {...settings} slickGoTo={currentSlide} className="slider">
        {images.map((image) => renderCarouselSlide(image))}
      </Slider>
      <Box className="carousel-controls">
        <PrevArrow onClick={handlePrevSlide} />

        <div className="dots-container">
          {/* Dots renderizados por slick-carousel */}
        </div>
        <NextArrow onClick={handleNextSlide} />
      </Box>
      <Modal
        open={modalOpen}
        handleClose={handleModalClose}
        selectedId={selectedImageId}
      />
      <style>
        {`
        .carousel-container {
          margin-top: 20px;
          width: 660px;
          margin-left: auto;
          margin-right: auto;
          border-radius: 5px;
          position: relative;
        }
        
        .slick-arrow {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          opacity: 1; /* Ajusta la opacidad */
          z-index: 1;
          box-shadow: none; /* Elimina la sombra */
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 35px;
          color: #fff;
          display: none; /* Oculta las flechas en pantallas pequeñas */
        }
      
        .next-arrow {
          right: 0;
        }
      
        .prev-arrow {
          left: 0;
        }
      
        .slick-dots {
          bottom: -25px;
          display: flex;
          justify-content: center; /* Centra los puntos indicadores */
        }
      
        .slick-dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin: 0 5px;
          opacity: 1; /* Ajusta la opacidad */
          background-color: #0C213D; /* Cambia el color a azul marino */
        }
      
        .slick-active .slick-dot {
          background-color: #1976D2; /* Color naranja oscuro */
        }
      
        .carousel-slide {
          outline: none;
          display: flex;
          align-items: center;
        }
            
        .carousel-image {
          max-width: 100%;
          max-height: 100%;
          border-radius: 5px;
      }
        @media screen and (max-width: 600px) {
          .carousel-container {
            margin-top: 8px;
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
      
          /* Ajusta el tamaño de las flechas en pantallas pequeñas */
          .slick-arrow {
            display: none; /* Muestra las flechas en pantallas grandes */
            /*  font-size: 25px;*/
          }
        }
        `}
      </style>
    </div>
  );
};

export default SliderRemesas;

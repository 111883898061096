import * as React from "react";
import { Box, Typography, Grid, Slide, Grow } from "@mui/material";
import imgPago from "../../img/Services/Ribbon/paymenth.png";
import imgAdornment from "../../img/Services/Ribbon/Adorno.png";
import imgActress from "../../img/Services/Ribbon/Element.png";
import ButtonArrow from "../ButtonArrow";
import GroupButton from "../GroupButtons";
//Comienzo Ventas por catalogo
import imgVentasCatalogoAvon from "../../img/Services/Avon.png";
import imgVentasCatalogoArabela from "../../img/Services/Arabela.webp";
import imgVentasCatalogoFuller from "../../img/Services/Fuller.webp";
import imgVentasCatalogoHerbalife from "../../img/Services/herbalife.webp";
import imgVentasCatalogoIlusion from "../../img/Services/ilusion.webp";
import imgVentasCatalogoJafra from "../../img/Services/jafra.webp";
import imgVentasCatalogoLbel from "../../img/Services/L'BEL.webp";
import imgVentasCatalogoNatura from "../../img/Services/natura.webp";
import imgVentasCatalogoTupperware from "../../img/Services/Tupperware.webp";
//Comienzo Servicios
import imgServiciosATT from "../../img/Services/at&t.webp";
import imgServiciosAxtel from "../../img/Services/Axtel.webp";
import imgServiciosCablemas from "../../img/Services/Cablemas.webp";
import imgServicioscdmx from "../../img/Services/cdMx.webp";
import imgServiciosCFE from "../../img/Services/CFE.webp";
import imgServiciosDish from "../../img/Services/dish.webp";
import imgServiciosEcogas from "../../img/Services/Ecogas.webp";
import imgServiciosStar from "../../img/Services/Star.webp";
import imgServiciosIave from "../../img/Services/iave.webp";
import imgServiciosInfovit from "../../img/Services/infonavid.webp";
import imgServiciosIusacell from "../../img/Services/iusacell.webp";
import imgServiciosIzzi from "../../img/Services/izzi.webp";
import imgServiciosMaxcom from "../../img/Services/Maxcom.webp";
import imgServiciosMaxigas from "../../img/Services/Maxigas.webp";
import imgServiciosMegaCable from "../../img/Services/Megacable.webp";
import imgServiciosMovistar from "../../img/Services/Movistar.webp";
import imgServiciosSKY from "../../img/Services/SKY.webp";
import imgServiciosGasNatural from "../../img/Services/gasnaturalmx.webp";
import imgServiciosTelcel from "../../img/Services/Telcel.webp";
import imgServiciosTelevia from "../../img/Services/Televia.webp";
import imgServiciosTelmex from "../../img/Services/Telmex.webp";
import imgServiciosTelnor from "../../img/Services/Telnor.webp";
import imgServiciosTotalPlay from "../../img/Services/Totalplay.webp";
import imgServiciosVETV from "../../img/Services/VETV.webp";

// Arreglo de imágenesServicios
const imagesServicios = [
  { id: 22, image: imgServiciosTelmex, type: "Servicio" },
  { id: 18, image: imgServiciosSKY, type: "Servicio" },
  { id: 20, image: imgServiciosTelcel, type: "Servicio" },
  { id: 17, image: imgServiciosMovistar, type: "Servicio" },
  { id: 1, image: imgServiciosATT, type: "Servicio" },
  { id: 25, image: imgServiciosVETV, type: "Servicio" },
  { id: 12, image: imgServiciosIusacell, type: "Servicio" },
  { id: 24, image: imgServiciosTotalPlay, type: "Servicio" },
  { id: 5, image: imgServiciosCFE, type: "Servicio" },
  { id: 2, image: imgServiciosAxtel, type: "Servicio" },
  { id: 16, image: imgServiciosMegaCable, type: "Servicio" },
  { id: 6, image: imgServiciosDish, type: "Servicio" },
  { id: 11, image: imgServiciosInfovit, type: "Servicio" },
  { id: 7, image: imgServiciosEcogas, type: "Servicio" },
  { id: 19, image: imgServiciosStar, type: "Servicio" },
  { id: 10, image: imgServiciosIave, type: "Servicio" },
  { id: 13, image: imgServiciosIzzi, type: "Servicio" },
  { id: 14, image: imgServiciosMaxcom, type: "Servicio" },
  { id: 15, image: imgServiciosMaxigas, type: "Servicio" },
  { id: 9, image: imgServiciosGasNatural, type: "Servicio" },
  { id: 3, image: imgServiciosCablemas, type: "Servicio" },
  { id: 21, image: imgServiciosTelevia, type: "Servicio" },
  { id: 23, image: imgServiciosTelnor, type: "Servicio" },
  { id: 4, image: imgServicioscdmx, type: "Servicio" },
];

// Arreglo de imágenesVentasCatalogos
const imagesServiciosVentasCatalogo = [
  { id: 1, image: imgVentasCatalogoAvon, type: "VentaCatalogo" },
  { id: 6, image: imgVentasCatalogoJafra, type: "VentaCatalogo" },
  { id: 3, image: imgVentasCatalogoFuller, type: "VentaCatalogo" },
  { id: 4, image: imgVentasCatalogoHerbalife, type: "VentaCatalogo" },
  { id: 8, image: imgVentasCatalogoTupperware, type: "VentaCatalogo" },
  { id: 2, image: imgVentasCatalogoArabela, type: "VentaCatalogo" },
  { id: 7, image: imgVentasCatalogoLbel, type: "VentaCatalogo" },
  { id: 5, image: imgVentasCatalogoIlusion, type: "VentaCatalogo" },
  { id: 9, image: imgVentasCatalogoNatura, type: "Ventacatalogo" },
];

export default function Services() {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <ButtonArrow />
      <GroupButton />
      <Grid container>
        <Grid item xs={4} sx={{ display: "flex" }}>
          <img
            src={imgAdornment}
            alt={imgAdornment}
            style={{ maxWidth: "100%" }}
            loading="lazy" // Lazy loading nativo
          />
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
          <Slide
            direction="right"
            in={true}
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 1000, exit: 1000 }}
          >
            <img
              src={imgPago}
              alt={imgPago}
              style={{ width: "243%" }}
              loading="lazy"
            />
          </Slide>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Grow in={true} timeout={{ enter: 2000, exit: 2000 }}>
            <img
              src={imgActress}
              alt={imgActress}
              style={{ maxWidth: "75%" }}
            />
          </Grow>
        </Grid>
      </Grid>

      <Box sx={{ width: "100%", backgroundColor: "#0C213D", p: 0 }}>
        <Typography
          variant="h5"
          align="center"
          color="#fff"
          sx={{ marginTop: 0 }}
        >
          <strong>Caja Solidaria La Huerta</strong>
          <br />
          Realiza el pago de tus servicios
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container spacing={2} maxWidth="lg" justifyContent="center">
          {imagesServicios.map((image) => (
            <Grid
              item
              xs={4}
              sm={3}
              md={3}
              lg={2}
              xl={2}
              key={image.id}
              sx={{
                mb: 1,
                marginTop: "12px",
                mx: { xs: 1, sm: 1 }, //Para cambiar el margen horizontal
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            >
              <img
                src={image.image}
                alt={image.type}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "10px",
                }}
                loading="lazy" // Lazy loading nativo
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ width: "100%", backgroundColor: "#0C213D", p: 0 }}>
        <Typography
          variant="h4"
          align="center"
          color="#ffff"
          sx={{ marginTop: 5 }}
        >
          Si vendes por
        </Typography>
        <Typography
          variant="h2"
          align="center"
          color="#ffff"
          sx={{ marginTop: 0 }}
        >
          <strong>CATÁLOGO</strong>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container spacing={5} maxWidth="lg" justifyContent="center">
          {imagesServiciosVentasCatalogo.map((image) => (
            <Grid
              item
              xs={4}
              sm={4}
              md={3}
              lg={2}
              xl={2}
              key={image.id}
              sx={{
                mb: 2,
                marginTop: "11px",
                mx: { xs: 1, sm: 1 }, //Para cambiar el margen horizontal
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            >
              <img
                src={image.image}
                alt={image.type}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
                loading="lazy" // Lazy loading nativo
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

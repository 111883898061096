import React from "react";
import { Box, Typography } from "@mui/material";
import Robot from "../../img/Robot_Update.png";
import LinearProgress from "./ProgressLinear.jsx";

export default function Update() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      textAlign="center"  
    >
      <Typography variant="h3" marginTop={1} color={"#FB6823"}>
        Página en construcción
      </Typography>
      <img
        src={Robot}
        alt="Update Robot"
        style={{ width: "285px", marginTop: "20px" }}
      />
      <LinearProgress />
      <Typography variant="h5" marginBottom={4} color={"#FB6823"}>
        Gracias por su atención
      </Typography>
    </Box>
  );
}

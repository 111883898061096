import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { Box, Typography } from "@mui/material";
import sucursalMatriz from "../img/Sucursales/matrizLaHuerta.webp";
import sucursalLaconcha from "../img/Sucursales/sucursalLaconcha.webp";
import sucursalCasimiro from "../img/Sucursales/sucursalCasimiro.webp";
import sucursalBarra from "../img/Sucursales/sucursalBarra.webp";
import sucursalCihuatlan from "../img/Sucursales/sucursalCihuatlan.webp";
import sucursalSanMateo from "../img/Sucursales/sucursalSanMateo.webp";
import sucursalVilla from "../img/Sucursales/sucursalVilla.webp";
import sucursalLoarado from "../img/Sucursales/sucursalLoarado.webp";
import sucursalLaManzanilla from "../img/Sucursales/SucursalLaManzanilla.webp";
import sucursalMiguelHidalgo from "../img/Sucursales/SucursalMiguelHidalgo.webp";
import sucursalMorelos from "../img/Sucursales/sucursalMorelos.webp"; 
import "../pages/map.css";

export default function Map() {
  // Carga el script de Google Maps
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBcLDMa4ysY4MTWKnv1T7jD_eFMh1lHdNU",
  });

  // Muestra un mensaje de carga hasta que el script cargue
  if (!isLoaded) return <Box>Loading..</Box>;

  return <Mapa />;
}

function Mapa() {
  // Ubicaciones de los marcadores
  const locations = [
    {
      lat: 19.4835,
      lng: -104.6411,
      description: "Matriz Caja Solidaria La Huerta",
      image: sucursalMatriz,
      ubicacion: "Emiliano Zapata No. 24, Colonia Centro.",
      telefono: "3573840480",
    }, // La Huerta Matriz
    {
      lat: 19.49122,
      lng: -104.56324,
      description: "Sucursal La concepción",
      image: sucursalLaconcha,
      ubicacion: "16 de septiembre #3, La concepción Jalisco.",
      telefono: "3573841643",
    }, // Sucursal La concepción
    {
      lat: 19.60369,
      lng: -104.43706,
      description: "Sucursal Casimiro castillo",
      image: sucursalCasimiro,
      ubicacion: "Av.Hidalgo #12 D, Sucursal Casimiro Castillo.",
      telefono: "3573881701",
    }, // Sucursal Casimiro castillo
    {
      lat: 19.62046,
      lng: -104.53466,
      description: "Sucursal Lo Arado",
      image: sucursalLoarado,
      ubicacion: "Hidalgo #128",
      telefono: "3573830835",
    }, // Sucursal Lo Arado
    {
      lat: 19.71863,
      lng: -104.60278,
      description: "Sucursal Villa purificación",
      image: sucursalVilla,
      ubicacion: "Independencia #5 B,Villa purificación Jalisco.",
      telefono: "3573750500",
    }, // Sucursal Villa purificaión
    {
      lat: 19.57765,
      lng: -105.08676,
      description: "Sucursal san Mateo",
      image: sucursalSanMateo,
      ubicacion: "Zenzontle #73, San Mateo Jalisco.",
      telefono: "3153339418",
    }, // Sucursal san Mateo
    {
      lat: 19.3615,
      lng: -104.89598,
      description: "Sucursal Miguel Hidalgo",
      image: sucursalMiguelHidalgo,
      ubicacion: " Pino #1 A, Miguel Hidalgo Jalisco.",
      telefono: "3153510796",
    }, // Sucursal Miguel Hidalgo
    {
      lat: 19.28192,
      lng: -104.78793,
      description: "Sucursal La Manzanilla",
      image: sucursalLaManzanilla,
      ubicacion: "MA. Asuncion #28, La Manzanilla Jalisco.",
      telefono: "3153515591",
    }, // Sucursal La Manzanilla
    {
      lat: 19.20375,
      lng: -104.68362,
      description: "Sucursal Barra de Navidad",
      image: sucursalBarra,
      ubicacion: "Av V.Veracruz # 175 D, Centro, Barra de Navidad Jalisco",
      telefono: "3153553898",
    }, // Sucursal Barra de Navidad
    {
      lat: 19.23778,
      lng: -104.55863,
      description: "Sucursal Cihuatlán",
      image: sucursalCihuatlan,
      ubicacion: "Alvaro Obregon #11 Interior 1, Cihuatlán Jalisco.",
      telefono: "3153551577",
    }, // Sucursal Cihuatlán
    {
      lat: 19.675755,
      lng: -105.184520,
      description: "Sucursal José Ma. Morelos",
      image: sucursalMorelos,
      ubicacion: "Av. Morelos #86, José Ma. Morelos, Municipio de Tomatlán.",
      telefono: "322 365 9011",
    }, // Sucursal Morelos
  ];

  // Define un nuevo estado para forzar la renderización del marcador y para el marcador seleccionado
  const [markerKey, setMarkerKey] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // Utiliza useEffect para cambiar el estado después de que se haya montado el componente
  useEffect(() => {
    setMarkerKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <GoogleMap
      zoom={10.2}
      center={locations[0]} // Centra el mapa en la primera ubicación
      mapContainerClassName="map-container"
    >
      {/* Renderiza un marcador para cada ubicación */}
      {locations.map((location, index) => (
        <Marker
          key={`${markerKey}-${index}`}
          position={location}
          onClick={() => {
            setSelectedMarker(location);
          }}
        />
      ))}

      {/* Si se seleccionó un marcador, muestra una ventana de información */}
      {selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => {
            setSelectedMarker(null);
          }}
        >
          <div>
            <Typography variant="h6">{selectedMarker.description}</Typography>
            <Typography variant="body1">{selectedMarker.ubicacion}</Typography>
            <Typography variant="body1">
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${selectedMarker.lat},${selectedMarker.lng}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Abrir en Google Maps
              </a>
            </Typography>
            <Typography variant="body">
              <a href={`tel:${selectedMarker.telefono}`}>
                {selectedMarker.telefono}
              </a>
            </Typography>
            <img
              src={selectedMarker.image}
              alt={selectedMarker.description}
              style={{ maxWidth: "80%", height: "auto" }}
            />
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import { Box } from "@mui/material";
import sucursalMatriz from "../../img/Services/Cajero/CajeroSucursallh.png";
import imgCajeroMatriz from "../../img/Services/Cajero/CajerosLaHuerta.webp";
import "./MapCajero.css";

export default function MapCajeros() {
  // Carga el script de Google Maps
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBcLDMa4ysY4MTWKnv1T7jD_eFMh1lHdNU",
  });

  // Muestra un mensaje de carga hasta que el script cargue
  if (!isLoaded) return <Box>Loading..</Box>;

  return <Mapa />;
}

function Mapa() {
  // Ubicaciones de los marcadores
  const locations = [
    {
      lat: 19.4835645,
      lng: -104.6413798,
      description: "Matriz Caja Solidaria La Huerta",
      image: imgCajeroMatriz,
      ubicacion: "Emiliano Zapata No. 24, Colonia Centro.",
      telefono: "357 384 0480",
    }, // La Huerta Matriz  
    {
      lat: 19.71863,
      lng: -104.60278,
      description: "Sucursal Villa purificación",
      image: sucursalMatriz,
      ubicacion: "Independencia #5 B,Villa purificación Jalisco.",
      telefono: "357 375 0500",
    }, // Sucursal Villa purificaión
    {
      lat: 19.57765,
      lng: -105.08676,
      description: "Sucursal san Mateo",
      image: sucursalMatriz,
      ubicacion: "Zenzontle #73, San Mateo Jalisco.",
      telefono: "315 333 9418",
    }, // Sucursal san Mateo
    {
      lat: 19.28192,
      lng: -104.78793,
      description: "Sucursal La Manzanilla",
      image: sucursalMatriz,
      ubicacion: "MA. Asuncion #28, La Manzanilla Jalisco.",
      telefono: "315 351 5591",
    }, // Sucursal La Manzanilla
    {
      lat: 19.675755,
      lng: -105.184520,
      description: "Sucursal José Ma. Morelos",
      image: sucursalMatriz,
      ubicacion: "Av. Morelos #86, José Ma. Morelos, Municipio de Tomatlán.",
      telefono: "322 365 9011",
    }, // Sucursal Morelos
  ];

  // Define un nuevo estado para forzar la renderización del marcador y para el marcador seleccionado
  const [markerKey, setMarkerKey] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // Utiliza useEffect para cambiar el estado después de que se haya montado el componente
  useEffect(() => {
    setMarkerKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <GoogleMap
      zoom={9}
      center={locations[0]} // Centra el mapa en la primera ubicación
      mapContainerClassName="map-container"
    >
      {/* Renderiza un marcador para cada ubicación */}
      {locations.map((location, index) => (
        <Marker
          key={`${markerKey}-${index}`}
          position={location}
          onClick={() => {
            setSelectedMarker(location);
          }}
        />
      ))}

      {/* Si se seleccionó un marcador, muestra una ventana de información */}
      {selectedMarker && (
  <InfoWindow
    position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
    onCloseClick={() => {
      setSelectedMarker(null);
    }}
  >
    <div>
      <h2>{selectedMarker.description}</h2>
      <h4>{selectedMarker.ubicacion}</h4>
      <h5>{selectedMarker.telefono}</h5>
      <p><a href={`https://www.google.com/maps/search/?api=1&query=${selectedMarker.lat},${selectedMarker.lng}`} target="_blank" rel="noopener noreferrer">Abrir en Google Maps</a></p>
     {/* <img
        src={selectedMarker.image}
        alt={selectedMarker.description}
        style={{ maxWidth: "100%", height: "auto" }}
      />*/}
      {/* Agrega este enlace a Google Maps */}
    </div>
  </InfoWindow>
)}
    </GoogleMap>
  );
}

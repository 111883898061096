import * as React from "react";
import Fab from "@mui/material/Fab";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ButtonArrow() {
  const handleClick = () => {
    // Anima el desplazamiento suavemente
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Fab
      color="primary"
      onClick={handleClick}
      style={{ opacity: 0.5 }}
      sx={{ position: "fixed", bottom: 16, right: 14, transition: "opacity 0.3s", width: "40px", height: "40px" }}
      title="Subir"
    >
      <KeyboardArrowUpIcon />
    </Fab>
  );
}

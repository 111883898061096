import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import IconTabRequeriments from "@mui/icons-material/DownloadDone";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtm";
import MonetizationTabIcon from "@mui/icons-material/MonetizationOn";

//change color icon Start
const OrangeArrowRightIcon = (props) => (
  <SvgIcon {...props}>
    <ArrowRightOutlinedIcon sx={{ color: "#FB6823" }} />
  </SvgIcon>
);
//change color icon End

export default function CreditoDetails({ plan }) {
  //Start tab var
  function TabPanel({ value, index, children }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  //End tab var

  //Start var tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //End var tabs

  return (
    <>
      {/*Tab products credit Start*/}
      <Box sx={{ display: "flex", width: "100%" }} mb={2}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Tab Details Credit"
          orientation="vertical"
        >
          <Tab
            style={{ width: "110px" }}
            icon={<BarChartIcon style={{ fontSize: 31 }} />}
            sx={{
              flexDirection: "row",
              "& .MuiSvgIcon-root": {
                ml: 2,
                mr: 10,
              },
            }}
          />
          <Tab
            style={{ width: "110px" }}
            icon={<IconTabRequeriments style={{ fontSize: 31 }} />}
            sx={{
              flexDirection: "row",
              "& .MuiSvgIcon-root": {
                ml: 2,
                mr: 10,
              },
            }}
          />
          <Tab
            style={{ width: "110px" }}
            icon={<CalendarMonthIcon style={{ fontSize: 31 }} />}
            sx={{
              flexDirection: "row",
              "& .MuiSvgIcon-root": {
                ml: 2,
                mr: 10,
              },
            }}
          />
          <Tab
            style={{ width: "110px" }}
            icon={<MonetizationTabIcon style={{ fontSize: 31 }} />}
            sx={{
              flexDirection: "row",
              "& .MuiSvgIcon-root": {
                ml: 2,
                mr: 10,
              },
            }}
          />
          <Tab
            style={{ width: "130px" }}
            icon={<LocalAtmOutlinedIcon style={{ fontSize: 30 }} />}
            sx={{
              flexDirection: "row",
              "& .MuiSvgIcon-root": {
                ml: 2,
                mr: 13,
              },
            }}
          />
        </Tabs>
        {/*Tab products credit End*/}

        {/*Contend tabs start*/}
        <Box sx={{ flexGrow: 1 }}>
          <TabPanel value={value} index={0}>
            {/* Contenido del primer tab */}
            <Box sx={{ ml: 1 }}>
              <Typography
                color={"#062035"}
                variant="h4"
                align="center"
                gutterBottom
              >
                Descripción
              </Typography>
              <Typography
                component="span"
                variant="body1"
                paragraph
                sx={{ textAlign: "justify" }}
              >
                {plan.description}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
                <img
                  src={plan.image}
                  alt={plan.name}
                  style={{ maxWidth: "75%" }}
                />
              </Box>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Box sx={{ ml: 1 }}>
              <Typography
                color={"#062035"}
                variant="h4"
                align="center"
                gutterBottom
              >
                Requisitos
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Documentacion}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.BaseDeOtorgamiento}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Avales}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.GarantiaHipotecaria}
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.GarantiasPrendarias}
                </ListItem>
              </List>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box sx={{ ml: 1 }}>
              <Typography
                color={"#062035"}
                variant="h4"
                align="center"
                gutterBottom
              >
                Plazos
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    Para pagos periodicos de capital e intereses hasta{" "}
                    {plan.PlazoMaximo}
                  </Box>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.PagoVencimiento}
                </ListItem>
              </List>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Box sx={{ ml: 1 }}>
              <Typography
                color={"#062035"}
                variant="h4"
                align="center"
                gutterBottom
              >
                Montos
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    Monto mínimo de{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      {plan.MontoMinimo}
                    </Box>
                  </Box>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    Monto máximo de{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      {plan.Montomaximo}
                    </Box>
                  </Box>
                </ListItem>
              </List>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Box sx={{ ml: 1 }}>
              <Typography
                color={"#062035"}
                variant="h4"
                align="center"
                gutterBottom
              >
                Tasas de interés
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  Tasa de interés ordinaria del {plan.TasaOrdinaria}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  Tasa de interés moratoria del {plan.TasaMoratoria}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    Costo Anual Total Promedio (
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      CAT
                    </Box>
                    ) de{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      {plan.Cat}
                    </Box>{" "}
                    anual sin IVA. "Para fines informativos y de comparación".
                    Fecha de cálculo 31/07/2024
                  </Box>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Comisiones}
                </ListItem>
              </List>
            </Box>
          </TabPanel>
        </Box>
      </Box>
      {/*End tabs details*/}
    </>
  );
}

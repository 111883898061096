import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const OrangeLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  backgroundColor: theme.palette.grey[300], // Color del fondo de la barra
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#FB6823', // Color de la barra de progreso
  },
}));

export default function LinearDeterminate() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '35%' }}>
      <OrangeLinearProgress variant="determinate" value={progress} />
    </Box>
  );
}

import React from "react";
import { Box, Typography } from "@mui/material";
import Robot from "../img/robot_not_fun.png";

export default function NotFound() {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h1" marginTop={2} color={"#FB6823"}>Error 404</Typography>
      <img src={Robot} alt="Not Fun Robot" style={{ width: "285px", marginTop: "20px" }} />
      <Typography marginBottom={7} variant="h4" color={"#FB6823"}>¡No encontrado!</Typography>   
    </Box>
  );
}

import React, { useState } from "react";
import "./footer.css";
import logo2 from "../img/LogoBlanco.webp";
import condusef from "../img/condusef.png";
import une from "../img/une.webp";
import cnbv from "../img/cnbv.webp";
import LocalPhoneSharpIcon from "@mui/icons-material/LocalPhoneSharp";
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";
import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import ModalUNE from "./ModalUNE";
import ModalDespachos from "./ModalDespachosCobranza";
import ImgBuroEntidades from "../img/BuroEntidades/Buro_Entidades_Financieras.webp";

import {
  Container,
  Grid,
  Typography,
  Box,
  Stack,
  Badge,
  SvgIcon,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

function CirculoIcon(props) {
  //codigo para trabajar construir el circulo de las redes sociales
  return (
    <SvgIcon {...props}>
      <path d="M 12 2 A 10 10 0 0 0 2 12 A 10 10 0 0 0 12 22 A 10 10 0 0 0 22 12 A 10 10 0 0 0 12 2 Z" />
    </SvgIcon>
  );
}

const Footer = () => {
  //Para quitar el subrayado de los links
  const linkStyle = {
    textDecoration: "none",
  };
  const phoneOficina1 = "3573840480";
  const phoneOficina2 = "3573841420";
  const phoneOficina4 = "8002252863";
  const websiteUrl = "https://www.condusef.gob.mx";
  let websiteCNBV = "https://www.gob.mx/cnbv";
  let CSLH =
    "https://www.facebook.com/people/Caja-Solidaria-La-Huerta-CF/100068460682221/";

  //Inicio Modal UNE
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //Fin Modal UNE

  //Inicio Modal despachos
  const [openModalDespachos, setOpenModalDespachos] = useState(false);

  const handleOpenModalDespachos = () => {
    setOpenModalDespachos(true);
  };

  const handleCloseModalDespachos = () => {
    setOpenModalDespachos(false);
  };
  //Fin Modal despachos
  return (
    <Container
      maxWidth="100%"
      sx={{ backgroundColor: "#0c213d", padding: 0, marginTop: 4 }}
    >
      <Grid container spacing={5}>
        {/* Código del Footer */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            "& .MuiTypography-root": {
              color: "#fff",
              "&:hover": {
                color: "orange",
              },
            },
            "& .MuiSvgIcon-root": {
              color: "#fff",
              "&:hover": {
                color: "orange",
              },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              CAJA SOLIDARIA
            </Typography>
            <Link to="/"> 
            <img
              style={{ marginTop: "-18%" }}
              src={logo2}
              alt="Logo Caja Solidaria"
              className="logo"
            />
            </Link>
            <Typography
              variant="h6"
              sx={{ color: "#fff", "&:hover": { color: "orange" }, mt: "-18%" }}
            >
              Oficinas Corporativas
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <LocationOnSharpIcon className="icon" />
              <Typography
                variant="body2"
                sx={{ color: "#fff", "&:hover": { color: "orange" } }}
              >
                Emiliano Zapata No. 24, Colonia Centro,
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#fff", "&:hover": { color: "orange" } }}
              >
                La Huerta, Jalisco, México. C.P. 48850
              </Typography>
            </Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              <LocalPhoneSharpIcon className="icon" />
              <Stack direction="column" spacing={1}>
                <a href={`tel:${phoneOficina1}`} style={linkStyle}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#fff", "&:hover": { color: "orange" } }}
                  >
                    357 384 0480
                  </Typography>
                </a>
                <a href={`tel:${phoneOficina2}`} style={linkStyle}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#fff", "&:hover": { color: "orange" } }}
                  >
                    357 384 1420
                  </Typography>
                </a>
                <a href={`tel:${phoneOficina4}`} style={linkStyle}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#fff", "&:hover": { color: "orange" } }}
                  >
                    800 225 2863
                  </Typography>
                </a>
              </Stack>
            </Stack>
            <Typography
              variant="h6"
              sx={{ color: "#fff", "&:hover": { color: "orange" } }}
            >
              Correo
            </Typography>
            <Box display="flex" alignItems="center">
              <MailOutlineSharpIcon className="icon" />
              <Typography
                variant="body2"
                sx={{ color: "#fff", "&:hover": { color: "orange" } }}
              >
                gerencia@cajasolidaria.net
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            "& .MuiTypography-root": {
              color: "#fff",
              "&:hover": {
                color: "orange",
              },
            },
            "& .MuiSvgIcon-root": {
              color: "#fff",
              "&:hover": {
                color: "orange",
              },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
              UNIDAD ESPECIALIZADA DE ATENCIÓN A USUARIOS
            </Typography>
            <div>
              <Button onClick={handleOpen}>
                <Box
                  sx={{
                    "& img": {
                      filter: "brightness(0) invert(1)",
                    },
                  }}
                >
                  <img src={une} alt="Logo Caja Solidaria" className="logo" />
                </Box>
              </Button>
              <ModalUNE open={open} handleClose={handleClose} />
            </div>
            <Typography variant="h6">Centro de Atención</Typography>
            <LocationOnSharpIcon className="icon" />
            <Typography variant="body2">
              Emiliano Zapata No. 24, Colonia Centro,
            </Typography>
            <Typography variant="body2">
              La Huerta, Jalisco, México. C.P. 48850
            </Typography>
            <Typography variant="h6">Atención telefónica:</Typography>
            <Box display="flex" alignItems="center">
              <LocalPhoneSharpIcon className="icon" />
              <a href={`tel:${phoneOficina1}`} style={linkStyle}>
                <span>357 384 0480</span>
              </a>
            </Box>
            <Typography variant="h6">Correo</Typography>
            <Box display="flex" alignItems="center">
              <MailOutlineSharpIcon className="icon" />
              <Typography variant="body2">une@cajasolidaria.net</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            "& .MuiTypography-root": {
              color: "#fff",
              "&:hover": {
                color: "orange",
              },
            },
            "& .MuiSvgIcon-root": {
              color: "#fff",
              "&:hover": {
                color: "orange",
              },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              CONDUSEF
            </Typography>
            <a
              href="https://www.condusef.gob.mx/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={condusef}
                alt="Logo Caja Solidaria"
                className="logo"
                loading="lazy"
              />
            </a>
            <Box display="flex" alignItems="center">
              <TravelExploreIcon className="icon" />
              {/* Enlace al sitio web */}
              <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                {websiteUrl}
              </a>
            </Box>
            <Typography variant="h6">Atención telefónico:</Typography>
            <Box display="flex" alignItems="center">
              <LocalPhoneSharpIcon className="icon" />
              <Typography variant="body2">555 340 0999</Typography>
            </Box>
            <Typography variant="h6">Correo</Typography>
            <Box display="flex" alignItems="center">
              <MailOutlineSharpIcon className="icon" />
              <Typography variant="body2">asesoria@condusef.gob.mx</Typography>
            </Box>

            <div>
              <Box display="flex" alignItems="center">
                <AccountBalanceIcon className="icon" />
                <Button
                  onClick={handleOpenModalDespachos}
                  style={{
                    fontFamily: "Arial, sans-serif",
                    textTransform: "none",
                  }}
                >
                  <Typography variant="body2">Despachos de Cobranza</Typography>
                </Button>
              </Box>
              <ModalDespachos
                open={openModalDespachos}
                handleClose={handleCloseModalDespachos}
              />
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              "& .MuiTypography-root": {
                color: "#fff",
                "&:hover": {
                  color: "orange",
                },
              },
              "& .MuiSvgIcon-root": {
                color: "#fff",
                "&:hover": {
                  color: "orange",
                },
              },
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              RELEVANTE
            </Typography>
            <Box
              sx={{
                "& img": {
                  filter: "brightness(0) invert(1)",
                },
              }}
            >
              <a
                href={websiteCNBV}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visita nuestro perfil de Facebook"
              >
                <img src={cnbv} alt="Logo Caja Solidaria" className="logo" />
              </a>
            </Box>

            <Link to="/contact" rel="noopener noreferrer" style={linkStyle}>
              {" "}
              <Typography variant="body2">Nuestras sucursales</Typography>
            </Link>
            <Link
              to="/avisoDePrivacidad"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              {" "}
              <Typography variant="body2">Aviso de Privacidad</Typography>
            </Link>
            <Link
              to="https://www.cnbv.gob.mx/SECTORES-SUPERVISADOS/SECTOR-POPULAR/Difusi%C3%B3n/Prensa%20%20Sociedades%20Cooperativas%20de%20Ahorro%20y%20Prstam/Comunicado_nuevas_SCAP.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              <Typography variant="body2">
                Autorizados por la C.N.B.V
              </Typography>
            </Link>

            <Typography variant="h6">REDES SOCIALES</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              {/* Redes sociales */}
              <a href={CSLH} target="_blank" rel="noopener noreferrer">
                <Badge
                  color="default"
                  overlap="circular"
                  variant="dot"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Stack
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CirculoIcon sx={{ fontSize: 43, color: "white" }} />
                    <FacebookTwoToneIcon
                      className="icon facebook"
                      sx={{
                        fontSize: 31,
                        color: "#0d409e",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </Stack>
                </Badge>
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visita nuestro canal de youtube"
              >
                <Badge
                  color="default"
                  overlap="circular"
                  variant="dot"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Stack
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CirculoIcon sx={{ fontSize: 43, color: "white" }} />
                    <YoutubeIcon
                      className="icon"
                      sx={{
                        fontSize: 31,
                        color: "#FF0000",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </Stack>
                </Badge>
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visita nuestro perfil de Instagram"
              >
                <Badge
                  color="default"
                  overlap="circular"
                  variant="dot"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Stack
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CirculoIcon sx={{ fontSize: 43, color: "white" }} />
                    <InstagramIcon
                      className="icon"
                      sx={{
                        fontSize: 31,
                        color: "#B71C1C",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </Stack>
                </Badge>
              </a>
            </Box>
            <Link
              to="/costosComisiones"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              <Typography variant="body2">
                Consulta los costos y comisiones de nuestros productos
              </Typography>
            </Link>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link to="/entidadesFinancieras">
              <img
                src={ImgBuroEntidades}
                alt="Logo Buro Entidades Financieras"
                className="logo"
              />
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 3,
            }}
          >
            {/*copyrigt*/}
            <hr />
            <Typography
              variant="body2"
              color="white"
              align="center"
              mb={"-4px"}
            >
              https://cajasolidarialahuerta.apiof.com.mx/
            </Typography>
            <Typography variant="body1" color="white" align="center">
              Copyright © {new Date().getFullYear()} | Caja Solidaria La Huerta
              | Todos los derechos reservados.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;

import React from "react";
import {
  Typography,
  Grid,
  Fade,
  Card,
  Button,
  CardActions,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ArrowForwardIos,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import ImgAhorro from "../../img/BoxFilter/Ahorro.webp";
import ImgCredito from "../../img/BoxFilter/Credito.webp";
import ImgInversion from "../../img/BoxFilter/Inversion.webp";

const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#062035",
  color: theme.palette.primary.contrastText,
}));

//Para agregarle el efecto hover a todo el card
const CustomCardStyled = styled(CustomCard)(({ theme }) => ({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.55)",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const BoxFilterProducts = () => {
  return (
    <>
      <Grid container spacing={8} alignItems="center" justifyContent="center">
        <Grid item xs={10} sm={10} md={3}>
          <Fade in timeout={2000}>
            <CustomCardStyled>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={ImgAhorro}
                  alt="Ahorro"
                  style={{ width: "100%", height: "auto" }}
                />
                <Typography align="center" variant="h5" component="div" sx={{ fontWeight: "bold" , color: "#FA6723"}}>
                  AHORRO
                </Typography>
                <Typography variant="body2" align="justify">
                  Te invitamos a descubrir nuestros productos de ahorro
                  disponibles para alcanzar tus metas financieras.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  endIcon={<ArrowForwardIos />}
                  component={Link}
                  to="/product?category=AHORRO"
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: "orange",
                      ".MuiButton-endIcon": {
                        transform: "translateX(0.40em) rotate(180deg)",
                        color: "orange",
                      },
                    },
                  }}
                >
                  Más información
                </Button>
              </CardActions>
            </CustomCardStyled>
          </Fade>
        </Grid>
        <Grid item xs={10} sm={10} md={3}>
          <Fade in timeout={3000}>
            <CustomCardStyled>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={ImgCredito}
                  alt="Crédito"
                  style={{ width: "100%", height: "auto" }}
                />
                <Typography align="center" variant="h5" component="div" sx={{ fontWeight: "bold" , color: "#FA6723"}}>
                  CRÉDITO
                </Typography>
                <Typography variant="body2" align="justify">
                  Descubre nuestras innovadoras, diversas y flexibles
                  alternativas de financiamiento que tenemos para ti.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  endIcon={<ArrowForwardIos />}
                  component={Link}
                  to="/product?category=CREDITO"
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: "orange",
                      ".MuiButton-endIcon": {
                        transform: "translateX(0.40em) rotate(180deg)",
                        color: "orange",
                      },
                    },
                  }}
                >
                  Más información
                </Button>
              </CardActions>
            </CustomCardStyled>
          </Fade>
        </Grid>
        <Grid item xs={10} sm={10} md={3}>
          <Fade in timeout={4000}>
            <CustomCardStyled>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={ImgInversion}
                  alt="Inversión"
                  style={{ width: "100%", height: "auto" }}
                />
                <Typography align="center" variant="h5" component="div" sx={{ fontWeight: "bold", color: "#FA6723" }}>
                  INVERSIÓN
                </Typography>
                <Typography variant="body2" align="justify">
                  Te ofrecemos diversas opciones de inversion a plazos que se
                  adaptan a tus necesidades financieras.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  endIcon={<ArrowForwardIos />}
                  component={Link}
                  to="/product?category=INVERSION"
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: "orange",
                      ".MuiButton-endIcon": {
                        transform: "translateX(0.40em) rotate(180deg)",
                        color: "orange",
                      },
                    },
                  }}
                >
                  Más información
                </Button>
              </CardActions>
            </CustomCardStyled>
          </Fade>
        </Grid>
      </Grid>
    </>
  );
};

export default BoxFilterProducts;

import * as React from "react";
import {
  Box,
  Typography,
  Grid,
  Grow,
  CardMedia,
  Fade,
  Card,
  Dialog,
  keyframes,
} from "@mui/material";
import imgRibbonFigure from "../img/promotions/Ribbon/Desvanecidos.png";
import ButtonArrow from "./ButtonArrow";
import ButtonGroup from "./GroupButtons";

//Comienzo Ventas por catalogo
import imgDiadelnino from "../img/promotions/DiaDelNino.webp";
import imgPromocion10Mayo from "../img/promotions/Promocion10mayo.webp";
import imgPromocionregresoClases from "../img/promotions/PromocionregresoClases.webp";
import imgPromcionDiamama from "../img/promotions/Diamama.webp";
import imgPromocionNavidad from "../img/promotions/PromocionNavidad.webp";
import imgRibbonProotions from "../img/promotions/Ribbon/ribbonPromciones.webp";

//Estilos para que el texto promociones
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// Arreglo de imágenesVentasCatalogos
const imagesServiciosVentasCatalogo = [
  {
    id: 1,
    image: imgPromocionNavidad,
    type: "Promociones",
    name: "Navidad para todos",
    status: "Activo",
  },
  {
    id: 2,
    image: imgDiadelnino,
    type: "Promociones",
    name: "Día del Niño",
    status: "Inactivo",
  },
  {
    id: 3,
    image: imgPromocion10Mayo,
    type: "Promociones",
    name: "Apertura de cuenta",
    status: "Inactivo",
  },
  {
    id: 4,
    image: imgPromocionregresoClases,
    type: "Promociones",
    name: "Regreso a clases",
    status: "Inactivo",
  },
  {
    id: 5,
    image: imgPromcionDiamama,
    type: "Promociones",
    name: "Mamá y Papá",
    status: "Inactivo",
  },
];

export default function Promotions() {
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const handleCardClick = (image) => {
    setSelectedImage(image.image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Separar las promociones por estado
  const activePromotions = imagesServiciosVentasCatalogo.filter(
    (item) => item.status === "Activo"
  );
  const inactivePromotions = imagesServiciosVentasCatalogo.filter(
    (item) => item.status === "Inactivo"
  );

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <ButtonArrow />
      <ButtonGroup />

      {/* Cabecera de Promociones */}
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        style={{
          backgroundImage: `url(${imgRibbonProotions})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid item xs={4} sx={{ display: "flex" }}>
          <Grow in={true} timeout={{ enter: 4000, exit: 4000 }}>
            <img
              src={imgRibbonFigure}
              alt={imgRibbonFigure}
              style={{
                maxWidth: "45%",
                filter: "brightness(0) invert(1)",
                transform: "rotate(180deg)",
              }}
              loading="lazy"
            />
          </Grow>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="h1"
            color="white"
            sx={{
              fontSize: {
                xs: "2.4rem",
                sm: "3rem",
                md: "5rem",
                animation: `${pulse} 2s infinite`,
              },
            }}
          >
            PROMOCIONES
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="h5"
        align="center"
        color="#0C213D"
        sx={{ marginTop: 5 }}
      >
        ¡Nuestras irresistibles!
      </Typography>
      <Typography
        variant="h3"
        align="center"
        color="#0C213D"
        sx={{ marginTop: 0 }}
      >
        <strong>PROMOCIONES</strong>
      </Typography>

      {/* Promociones Activas */}
      <Typography
        variant="h5"
        align="center"
        color="#0C213D"
        sx={{ marginTop: 5 }}
      >
        Activo:
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1%", mx: "2%" }}>
        <Grid container spacing={4} maxWidth="lg" justifyContent="center">
          {activePromotions.map((image) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              key={image.id}
              sx={{ mb: 5, marginTop: "13px" }}
            >
              <Fade in timeout={3000}>
                <Card
                  sx={{
                    position: "relative",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => handleCardClick(image)}
                >
                  <CardMedia
                    component="img"
                    height="325"
                    image={image.image}
                    alt={image.type}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      bgcolor: "#0C213D",
                      opacity: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "opacity 0.3s",
                      "&:hover": {
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Box>
                      <Typography variant="h7" color="white">
                        {image.name}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Promociones Inactivas */}
      <Typography
        variant="h5"
        align="center"
        color="#0C213D"
        sx={{ marginTop: 5 }}
      >
        Sin Vigencia:
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1%", mx: "2%" }}>
        <Grid container spacing={4} maxWidth="lg" justifyContent="center">
          {inactivePromotions.map((image) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              key={image.id}
              sx={{ mb: 5, marginTop: "13px" }}
            >
              <Fade in timeout={3000}>
                <Card
                  sx={{
                    position: "relative",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => handleCardClick(image)}
                >
                  <CardMedia
                    component="img"
                    height="325"
                    image={image.image}
                    alt={image.type}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      bgcolor: "#0C213D",
                      opacity: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "opacity 0.3s",
                      "&:hover": {
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Box>
                      <Typography variant="h7" color="white">
                        {image.name}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Diálogo para imágenes */}
      <Dialog open={open} onClose={handleClose} onClick={handleClose}>
        <img src={selectedImage} alt="" loading="lazy" />
      </Dialog>
    </Box>
  );
}

import React, { useState } from "react";
import SliderComponent from "../slider/slider"; //slider que de las sucursales
import SliderProducts from "./sliderHomeProducts/slider.jsx";
import ImgTeam from "../img/Empleados.webp";
import BtnArrow from "./ButtonArrow";
import ImgFoocop from "../img/FOCOOP.webp";

//import RibbonPartners from "./RibbonPartners.jsx";
import RoomIcon from "@mui/icons-material/Room";
import GroupButtons from "./GroupButtons.jsx";
import {
  Typography,
  Box,
  Container,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material";
import { keyframes, styled } from "@mui/material/styles";
import "./home.css";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BoxFilter from "./BoxFilterProducts/BoxFilterProducts.jsx";

const Img = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "750px",
  height: "auto",
  borderRadius: "14px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "500px",
    borderRadius: "15px",
  },
}));

const Home = () => {
  const sucursales = [
    {
      nombre: "Matriz Caja Solidaria La Huerta",
      telefono: "357 384 0480",
      telefono2: "357 384 1420",
      telefono3: "357 384 1783",
    },
    { nombre: "Sucursal La Concepción", telefono: "357 384 1643" },
    { nombre: "Sucursal Casimiro Castillo", telefono: "357 388 1701" },
    { nombre: "Sucursal Lo Arado", telefono: "3573830835" },
    { nombre: "Sucursal Villa Purificación", telefono: "357 375 0500" },
    { nombre: "Sucursal San Mateo", telefono: "315 333 9418" },
    { nombre: "Sucursal Miguel Hidalgo", telefono: "315 351 0796" },
    { nombre: "Sucursal La Manzanilla", telefono: "315 351 5591" },
    { nombre: "Sucursal Barra de Navidad", telefono: "315 355 3898" },
    { nombre: "Sucursal Cihuatlán", telefono: "315 355 1577" },
    { nombre: "Sucursal Morelos", telefono: "322 365 9011" },
  ];

  //Para quitar el subrayado de los links
  const linkStyle = {
    textDecoration: "none",
  };

  //Para calcular si esta expandido el acordeon
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //Para hacer que el titulo bienvenido tenga animacion de pulsacion
  const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

  return (
    <div>
      <SliderProducts />
      <BtnArrow />
      <GroupButtons />

      {/* Conoce nuestros productos */}
      <Container
        maxWidth="100%"
        sx={{ backgroundColor: "white", marginTop: "13vw" }}
      >
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ color: "#1976D2", marginBottom: "5px" }}
        >
          DESCUBRE
        </Typography>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: "#062035", marginBottom: "22px" }}
        >
          ¡NUESTROS PRODUCTOS!
        </Typography>
        <BoxFilter />
      </Container>
      {/* Fin Conoce nuestros productos */}

      <Container sx={{ backgroundColor: "white", marginTop: 1 }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Grid container item xs={12} md={8} mt={7} justifyContent="center">
            <Typography
              variant="h3"
              align="center"
              gutterBottom
              sx={{ color: "#0C213D", marginBottom: "5px" }}
            >
              VISÍTANOS
            </Typography>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{ color: "#062035", marginBottom: "22px" }}
            >
              Nos encantaría recibirte en cualquiera de nuestras 11 sucursales.
            </Typography>
          </Grid>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <SliderComponent />
          </Grid>
          <Grid item xs={12} md={4} mt={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Nuestro Horario </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lunes a Viernes: 9 AM a 4 PM
                  <br />
                  Sábado: 9 AM a 1 PM
                  <br />
                  Domingo: Descansamos
                </Typography>
                <a
                  href="https://www.gob.mx/cnbv/acciones-y-programas/calendario-cnbv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography style={{ color: "#FA6723" }}>
                    Consulta calendario oficial de dias inhábiles
                  </Typography>
                </a>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginTop: "1rem" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Números de Teléfono</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {sucursales.map((sucursal, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index + 2}`}
                    onChange={handleChange(`panel${index + 2}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 2}a-content`}
                      id={`panel${index + 2}a-header`}
                    >
                      <Typography
                        style={{
                          color:
                            expanded === `panel${index + 2}`
                              ? "#FB6823"
                              : "inherit",
                        }}
                      >
                        {sucursal.nombre}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <a href={`tel:${sucursal.telefono}`} style={linkStyle}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#0C213D",
                            "&:hover": { color: "#FB6823" },
                          }}
                        >
                          {sucursal.telefono}
                        </Typography>
                      </a>
                      <a href={`tel:${sucursal.telefono}`} style={linkStyle}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#0C213D",
                            "&:hover": { color: "#FB6823" },
                          }}
                        >
                          {sucursal.telefono2}
                        </Typography>
                      </a>
                      <a href={`tel:${sucursal.telefono}`} style={linkStyle}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#0C213D",
                            "&:hover": { color: "#FB6823" },
                          }}
                        >
                          {sucursal.telefono3}
                        </Typography>
                      </a>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
            <Button
              variant="contained"
              color="primary"
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "85%", // Ajusta el ancho del botón al 50% del contenedor
                paddingY: 0, // Ajusta el padding vertical para hacer el botón más delgado
                marginTop: 2,
              }}
              component={Link}
              to="/contact" // Añade esta línea
            >
              <Stack direction="column" alignItems="center">
                <RoomIcon />
                Cómo llegar
              </Stack>
            </Button>
          </Grid>
        </Grid>
      </Container>

      {/* Quienes somos */}
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: "#0C213D",

          marginTop: 10,
          width: "calc(100% - 19px)",
          borderRadius: 4,
        }}
      >
        <Grid container spacing={0.5} alignItems="center">
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ marginX: { xs: 4, sm: 4 } }}>
              <Typography
                variant="h4"
                sx={{
                  color: "#fff",
                  "&:hover": { color: "#orange" },
                  textAlign: "center",
                }}
              >
                QUIÉNES SOMOS
              </Typography>
              <Typography variant="h5" sx={{ color: "#fff" }}>
                Caja Solidaria La Huerta
              </Typography>
              <Box sx={{ mb: 3.5, textAlign: "justify" }}>
                <Typography variant="body" sx={{ color: "#fff" }}>
                  Somos una Sociedad Cooperativa de Ahorro y Préstamo, que
                  brinda productos y servicios financieros de manera segura y
                  confiable a todos nuestros socios y ahorradores menores.
                  Tenemos presencia en la región Costa Sur del Estado de Jalisco
                  en nuestras 10 sucursales y nos encontramos autorizados por la
                  Comisión Nacional Bancaria y de Valores.
                </Typography>
              </Box>
              <Box sx={{ mb: 3.5, textAlign: "justify" }}>
                <Typography variant="body" sx={{ color: "#fff" }}>
                  Te damos una cálida bienvenida a nuestro sitio web, donde te
                  invitamos a explorar una amplia gama de productos y servicios
                  que ofrecemos para ayudarte a alcanzar tus metas financieras.
                </Typography>
              </Box>
              <Typography
                variant="h5"
                mb={"2rem"}
                sx={{
                  color: "#fff",
                  "&:hover": { color: "orange" },
                  textAlign: "center",
                  animation: `${pulse} 2s infinite`,
                }}
              >
                BIENVENIDO
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            mt={1}
            mb={1}
            sx={{ mx: { xs: 1, sm: 0 } }}
          >
            <Img src={ImgTeam} alt="Imagen del Equipo CSLH MATRIZ" />
          </Grid>
        </Grid>
      </Container>
      {/* Fin de quienes somos */}

      {/* Start Authorization CNBV */}
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{  
            padding: "10px 0",
            marginBottom: "4rem",
            borderRadius: "3px",
            marginTop: "5vw",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              marginLeft: "2rem",
              marginRight: "2rem",
              order: { xs: 2, sm: 1 },
            }}
          >
            <Typography variant="h6" mb="10px" sx={{ fontWeight: "bold" }}>
              Caja Solidaria La Huerta Autorizada por C.N.B.V
            </Typography>
            <Typography variant="body2" align="justify">
              Te invitamos a verificar el oficio de autorizacion por la Comision
              Nacional Bancaria y de Valores.
            </Typography>
          </Box>
          <Box
            mt={3}          
            xs={12}
            sm={12}
            md={4}
            sx={{
              order: { xs: 2, sm: 1 },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="//www.cnbv.gob.mx/SECTORES-SUPERVISADOS/SECTOR-POPULAR/Difusi%C3%B3n/Prensa%20%20Sociedades%20Cooperativas%20de%20Ahorro%20y%20Prstam/Comunicado_nuevas_SCAP.pdf"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ maxWidth: "200px" }} // Ajusta el valor de maxWidth según tu preferencia
            >
              Validación
            </Button>
          </Box>
        </Box>
      </Container>
      {/*Start Authorization CNBV */}

      {/* Start Authorization CNBV */}
      <Container maxWidth="lg" sx={{ marginBottom: "3rem" }}>
        <Box sx={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <Typography variant="body2" align="justify" mb="1%">
            Producto garantizado por el Fideicomiso Fondo de Supervisión
            Auxiliar de Sociedades Cooperativas de Ahorro y Préstamo de
            Proteccion a sus Ahorradores (FOOCOP) por 25,000 UDIS ( Unidades de
            Inversión ) por persona.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={ImgFoocop}
              style={{ width: "180px" }}
              alt="Logotipo de Foocop"
            />
          </Box>
        </Box>
      </Container>
      {/*Start Authorization CNBV */}
    </div>
  );
};

export default Home;

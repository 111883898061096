import React from "react";
import {
  Box,
  Container,
  Tabs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Grid,
  TableRow,
  IconButton,
  Collapse,
  keyframes,
} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import imgRibbon from "../img/ribbonParthners.png";
import Tab from "@mui/material/Tab";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import ExpandFuntionIcon from "@mui/icons-material/AddCircle";
import RetractFunctionIcon from "@mui/icons-material/RemoveCircle";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ImgSave from "../img/AhorroCSLH.png";
import styled from "@emotion/styled";
import ImgSocio from "../img/TarjetCSLH.png";
//Boton para subir la pantalla
import ButtonArrow from "./ButtonArrow";
import GroupButton from "./GroupButtons";

//Para hacer las pulsaciones del texto principal
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Img = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "750px",
  height: "auto",
  borderRadius: "14px",
}));

// Icons change color start
const OrangeArrowRightIcon = (props) => (
  <SvgIcon {...props}>
    <ArrowRightIcon sx={{ color: "#FB6823" }} />
  </SvgIcon>
);

const NavyExpandicon = (props) => (
  <SvgIcon {...props}>
    <ExpandFuntionIcon sx={{ color: "#0C213D" }} />
  </SvgIcon>
);

const OrangeRetractIcon = (props) => (
  <SvgIcon {...props}>
    <RetractFunctionIcon sx={{ color: "#FB6823" }} />
  </SvgIcon>
);
//Iconos change color end

const data = [
  {
    id: 1,
    name: "Requisitos:",
    details: "",
  },
  {
    id: 2,
    name: "Tu compromiso con la cooperativa:",
    details: "",
  },
  {
    id: 3,
    name: "Consideraciones:",
    details: "",
  },
];

const data2 = [
  {
    id: 4,
    name: "Requisitos:",
    details: "",
  },
];

function TabPanel({ value, index, children }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Partherns() {
  const [value, setValue] = React.useState(0);
  const [expandedRows, setExpandedRows] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = (id) => {
    const currentIndex = expandedRows.indexOf(id);
    const newExpandedRows = [...expandedRows];

    if (currentIndex === -1) {
      newExpandedRows.push(id);
    } else {
      newExpandedRows.splice(currentIndex, 1);
    }

    setExpandedRows(newExpandedRows);
  };

  return (
    <Container maxWidth="lg">
      <ButtonArrow />
      <GroupButton />
      {/*Ribbon Start*/}
      <Box
        sx={{
          my: 4,
          backgroundColor: "#0C213D",
          display: "flex",
          flexDirection: "column", // Añade esto
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "6cm", // Añade esto
        }}
      >
        <img
          src={imgRibbon}
          alt="Ribbon"
          style={{
            width: "100%",
            height: "100%", // Cambia esto
            objectFit: "cover",
            position: "absolute",
          }}
          loading="lazy" // Lazy loading nativo
        />
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          color={"white"}
          sx={{
            fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem" },
            animation: `${pulse} 2s infinite`,
          }}
        >
          SOCIOS
        </Typography>
      </Box>
      {/*Ribbon Start*/}

      {/*Socios Start*/}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        color={"#0C213D"}
        sx={{
          top: { xs: "32%", sm: "32%", lg: "37%" },
          left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
        }}
      >
        Beneficios de Ser Socio
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
        Ser socio de <strong>Caja Solidaria La Huerta</strong> te brinda
        diversos beneficios como acceder a créditos, cuentas de ahorro e
        inversión, cobro de remesas, pago de diversos servicios, entre otros.
        Además, podrás contar con el beneficio de un paquete funerario
        totalmente gratuito en caso de que llegarás a fallecer.
      </Typography>
      {/*Socios End*/}

      {/*Socios tabs start*/}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Tab partners"
            centered
          >
            <Tab
              icon={<PeopleOutlineIcon />}
              label="Socios"
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  marginRight: 1,
                },
              }}
            />
            <Tab
              icon={<SentimentVerySatisfiedIcon />}
              label="Ahorradores menores"
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  marginRight: 1,
                },
              }}
            />
          </Tabs>
        </Box>

        {/*Contend tabs start*/}
        <TabPanel value={value} index={0}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"#0C213D"}
            sx={{
              top: { xs: "32%", sm: "32%", lg: "37%" },
              left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
            }}
          >
            ¿Quierés ser socio?
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
            Nos gustaría que formaras parte de esta gran cooperativa, es muy
            fácil y sencillo.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <IconButton onClick={() => handleExpandClick(row.id)}>
                            {expandedRows.includes(row.id) ? (
                              <OrangeRetractIcon />
                            ) : (
                              <NavyExpandicon />
                            )}
                          </IconButton>
                          {row.name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Collapse in={expandedRows.includes(row.id)}>
                            {row.id === 1 ? (
                              <List>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Cubrir la parte social de $700.00 Pesos.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Credencial de elector.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Acta de nacimiento
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Comprobante de domicilio reciente.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  CURP
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Comprobante de ingresos (En su caso).
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Acta de matrimonio (En su caso).
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Copia de R.F.C (En su caso).
                                </ListItem>
                              </List>
                            ) : (
                              row.details
                            )}
                          </Collapse>
                          <Collapse in={expandedRows.includes(row.id)}>
                            {row.id === 2 ? (
                              <List>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Cumplir fielmente los estatutos y los
                                  reglamentos de nuestra cooperativa, además de
                                  los acuerdos de las asambleas y los tomados
                                  por el Consejo de Administración.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Defender y respetar los principios y el objeto
                                  social, buscando en todo momento el fomento y
                                  la integración de sus miembros.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Asistir y participar en las asambleas.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Participar en el desarrollo económico y social
                                  de nuestra cooperativa.
                                </ListItem>
                              </List>
                            ) : (
                              row.details
                            )}
                          </Collapse>
                          <Collapse in={expandedRows.includes(row.id)}>
                            {row.id === 3 ? (
                              <List>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Brinda tu número telefónico fijo o celular.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Ten a la mano los datos de tus beneficiarios
                                  (nombre completo, fecha de nacimiento,
                                  domicilio particular y número telefónico)
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  En caso de ser apoderado legal o propietario
                                  real, se requerirá el documento que así lo
                                  avale.
                                </ListItem>
                              </List>
                            ) : (
                              row.details
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} md={6}>
              <Img src={ImgSocio} alt="Socio caja solidaria la huerta" loading="lazy" />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"#FB6823"}
            sx={{
              top: { xs: "32%", sm: "32%", lg: "37%" },
              left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
            }}
          >
            ¡Hola a todos los jóvenes ahorradores!
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
            Si aún no has alcanzado la mayoría de edad, puedes acceder a la Caja
            Solidaria La huerta como Ahorrador Menor; el proceso es muy rápido.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data2.map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <IconButton onClick={() => handleExpandClick(row.id)}>
                            {expandedRows.includes(row.id) ? (
                              <OrangeRetractIcon />
                            ) : (
                              <NavyExpandicon />
                            )}
                          </IconButton>
                          {row.name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Collapse in={expandedRows.includes(row.id)}>
                            {row.id === 4 ? (
                              <List>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Acta de nacimiento del menor.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  CURP del menor.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Credencial de elector del padre o tutor.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Comprobante de domicilio reciente.
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <OrangeArrowRightIcon />
                                  </ListItemIcon>
                                  Apertura de la cuenta desde 10 pesos.
                                </ListItem>
                              </List>
                            ) : (
                              row.details
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} md={6}>
              <Img src={ImgSave} alt="Tarjeta de debito CSLH"   loading="lazy" />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Container>
  );
}

import React, { useMemo } from 'react';
import Logo from '../img/25Aniversario.webp';
import {  Box } from '@mui/material';
import ProgressBar from "./progressBar.jsx";

const SplashScreen = () => {
  const rotateStyles = useMemo(() => ({
    animation: 'rotate 2s linear infinite',
    width: '20%',
    '@keyframes rotate': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' },
    },
  }), []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="100vh"
    >
      <img src={Logo} alt="Logotipo CSLH" style={rotateStyles} />
      <ProgressBar/>
    </Box>
  );
};

export default SplashScreen;


import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Stack,
  Grid,
  keyframes,
} from "@mui/material";
import MisionImg from "../img/Mision.webp";
import VisionImg from "../img/Vision.webp";
import ValoresImg from "../img/Valores.webp";
import "../pages/about.css";
import imgRibbon from "../img/ribbonAbout.webp";
import ButtonArrow from "./ButtonArrow";
import GroupButton from "./GroupButtons";

//Para hacer que el titulo principal tenga animacion de pulsacion
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export default function History() {
  const [selected, setSelected] = useState(null);

  const handleClick = (value) => {
    setSelected(value);
  };

  return (
    <Container maxWidth="lg">
      <ButtonArrow />
      <GroupButton />

      <Box
        sx={{
          my: 4,
          backgroundColor: "#0C213D",
          display: "flex",
          flexDirection: "column", // Añade esto
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "6cm", // Añade esto
        }}
      >
        <img
          src={imgRibbon}
          alt="Ribbon"
          style={{
            width: "100%",
            height: "100%", // Cambia esto
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          color={"white"}
          sx={{
            fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem" },
            animation: `${pulse} 2s infinite`,
          }}
        >
          ANTECEDENTES
        </Typography>
      </Box>    

      {/*History Start*/}
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" color={"#0c213d"} gutterBottom>
          Historia
        </Typography>
        <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
          La Caja Solidaria La Huerta, inició operaciones un 20 de agosto de
          1999, con un capital inicial de $800,000.00 MXN, proveniente del
          crédito a la palabra de 383 socios, considerándose en su momento “un
          gran logro para el sector social” (Manuscrito de la sociedad, Breve
          reseña, 2006) teniendo como objetivo el apoyar a sus socios del
          Municipio con sus proyectos productivos y sus necesidades básicas.
        </Typography>
        <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
          En el año 2002, se funda la primera sucursal en la localidad de Miguel
          Hidalgo en el Municipio de la Huerta. Esta experiencia se replica en
          los años posteriores estableciendo 6 sucursales restantes en los 4
          Municipios que conforman el área de atención de la Caja y que son
          Casimiro Castillo, Cihuatlán, Villa Purificación y la Huerta. Las
          sucursales que se establecen son Casimiro Castillo, Lo Arado, Villa
          Purificación, Cihuatlán, La Manzanilla, Miguel Hidalgo y San Mateo,
          que se incorporan a la Matriz que tiene su domicilio en el centro del
          Municipio de la Huerta.
        </Typography>
        <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
          Una fecha trascendental para la cooperativa y que ha quedado plasmada
          en sus muros, es el 26 de noviembre de 2006, fecha en que se
          inauguraron las oficinas propias. Siendo unas oficinas funcionales,
          que constituyeron un ejemplo de instalaciones para la región y en
          general para las cajas solidarias en el País.
        </Typography>
        <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
          En el año 2014, se inauguró la sucursal de la Concepción, en el
          municipio de la Huerta. En el año 2014 se realizaron gestiones ante el
          INAES para obtener apoyos para la instalación de una sucursal y tres
          cajeros automáticos, como una manera de contribuir al desarrollo e
          inclusión financiera. En 2016, nuevamente preparó un proyecto
          estratégico financiero para solicitar apoyos a INAES, siendo
          beneficiada para la mejora de la sucursal de Villa Purificación y la
          instalación de un cajero más en esta sucursal, que beneficia a la
          gente de los pueblos aledaños.
        </Typography>
      </Box>
      {/*History End*/}

      {/*Boton group inicio*/}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 8 }}>
        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
          <Button
            variant="contained"
            sx={{
              width: 250,
              height: 45,
              backgroundColor:
                selected === "mision" ? "#0c213d" : "primary.main",
              "&:hover": {
                backgroundColor: "#0c213d",
              },
            }}
            onClick={() => handleClick("mision")}
          >
            MISIÓN
          </Button>
          <Button
            variant="contained"
            sx={{
              width: 250,
              height: 45,
              backgroundColor:
                selected === "vision" ? "#0c213d" : "primary.main",
              "&:hover": {
                backgroundColor: "#0c213d",
              },
            }}
            onClick={() => handleClick("vision")}
          >
            VISIÓN
          </Button>
          <Button
            variant="contained"
            sx={{
              width: 250,
              height: 45,
              backgroundColor:
                selected === "valores" ? "#0c213d" : "primary.main",
              "&:hover": {
                backgroundColor: "#0c213d",
              },
            }}
            onClick={() => handleClick("valores")}
          >
            VALORES
          </Button>
        </Stack>
      </Box>
      {selected === "mision" && (
        <Box sx={{ my: 4, mb: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                align="center"
                color={"#0c213d"}
                gutterBottom
              >
                Misión
              </Typography>
              <Typography
                variant="body1"
                align="justify"
                sx={{ marginTop: "2rem" }}
              >
                Somos una sociedad cooperativa de ahorro y préstamo, que brinda
                productos y servicios financieros de calidad a todos nuestros
                socios y ahorradores menores de manera segura y confiable.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/*Imagen*/}
              <img
                src={MisionImg}
                alt="Imagen representativa mision"
                className="imgMision"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {selected === "vision" && (
        <Box sx={{ my: 4, mb: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                align="center"
                color={"#0c213d"}
                gutterBottom
              >
                Visión
              </Typography>
              <Typography variant="body1" align="justify">
                Seremos una cooperativa de ahorro y préstamo que a mediano plazo
                se consolide en la región donde tenemos presencia, como una de
                las mejores opciones en la oferta de productos y servicios
                financieros, que contribuyan a una mejor calidad de vida de sus
                asociados.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/*Imagen */}
              <img
                src={VisionImg}
                alt="Imagen representativa mision"
                className="imgMision"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {selected === "valores" && (
        <Box sx={{ my: 4, mb: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                align="center"
                color={"#0c213d"}
                gutterBottom
              >
                Valores
              </Typography>
              <Typography variant="body1" align="justify">
                En Caja Solidaria La Huerta, el respeto, la equidad, la
                democracia, la seguridad, el compromiso, la confianza y la
                cooperación son valores esenciales que crean un ambiente
                inclusivo y productivo.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/*Imagen */}
              <img
                src={ValoresImg}
                alt="Imagen representativa valores"
                className="imgMision"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {/*Boton group final*/}
    </Container>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Grow,
  Box,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  Paper,
  tableCellClasses,
  createTheme,
  Alert,
  AlertTitle,
} from "@mui/material";
import imgRibbon from "../../img/cotizador/ribbonCotizadorcredito.png";
import imgRibbonFigure from "../../img/cotizador/figure.png";
//imagenes de los creditos
import soliTarjetaDorada from "../../img/Saved/solitarjetaDorada.png";
import soliProductivo from "../../img/Saved/soliProductivo.png";
import soliPersonal from "../../img/Saved/soliPersonal.png";
import soliVivienda from "../../img/Saved/soliVivienda.png";
import soliExpress from "../../img/Saved/SoliExpress.png";
import soliHipotecario from "../../img/Saved/soliHipotecario.png";
import soliAuto from "../../img/Saved/soliAuto.png";
import soliAutomatico from "../../img/Saved/soliautomatico.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Boton para subir la pantalla
import { addDays, addMonths, format, isWeekend } from "date-fns";
import ButtonArrow from "../ButtonArrow.jsx";
import "./cotizadorCredito.css";
import styled from "@emotion/styled";

const credit = [
  {
    id: `1`,
    name: "SOLI-PERSONAL",
    type: "CREDITO",
    image: soliPersonal,
    description: "Créditos destinados para gastos personales",
    TasaOrdinaria: 24.0,
    TasaMoratoria: 48.0,
    MontoMinimo: 500,
    Montomaximo: 400000,
    PlazoMaximo: 96,
    FormasDepago:
      "Pago único de capital e intereses (máximo 18 meses), pagos periódicos de intereses y capital",
    BaseDeOtorgamiento: "Se dejara el 10% en garantia liquida",
    Avales: "De 1 a 2 obligados solidarios (Avales)",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "No requiere de garantía Liquida",
    GarantiasPrendarias: "Solo si el comité lo solicita",
    TipoGarantia: "Si requiere garantía quirografaria",
    Requisitos:
      "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "Costo Anual Total Promedio (CAT) de 26.84% anual sin IVA. Para fines informativos y de comparación. fecha de cálculo 16/01/2024",
  },
  {
    id: `2`,
    name: "SOLI-AUTOMATICO",
    type: "CREDITO",
    image: soliAutomatico,
    description:
      "Para créditos que se encuentren garantizados al 100% por sus haberes",
    TasaOrdinaria: 14.4,
    TasaMoratoria: 48.0,
    MontoMinimo: 500,
    Montomaximo: 270000,
    PlazoMaximo: 24,
    FormasDepago:
      "Pago único de capital e intereses (máximo 18 meses), pagos periódicos de intereses y capital.",
    BaseDeOtorgamiento:
      "Se otorgara el 90% de tus haberes si el plazo máximo es de 12 meses o hasta 18 meses siempre y cuando sea en pagos periódicos. de lo contrario el 80% de tus haberes si el plazo es a un solo vencimiento mayor a 12 meses o hasta 24 meses",
    Avales: "Sin avales",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "Si requiere de garantía Liquida",
    GarantiasPrendarias: "No aplican las garantías prendarias",
    TipoGarantia: "Si requiere garantía quirografaria",
    Requisitos:
      "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial.",
    Cat: "Costo Anual Total Promedio (CAT) de 15.40% anual sin IVA. Para fines informativos y de comparación. fecha de cálculo 16/01/2024",
  },
  {
    id: `3`,
    name: "SOLI-EXPRES",
    type: "CREDITO",
    image: soliExpress,
    description:
      "Crédito de entrega inmediata para cualquier necesidad de financiamiento",
    TasaOrdinaria: 36.0,
    TasaMoratoria: 48.0,
    MontoMinimo: 500,
    Montomaximo: 100000,
    PlazoMaximo: 60,
    FormasDepago: "Pagos fijos de capital e intereses",
    BaseDeOtorgamiento:
      "No requiere garantía liquida, pero si requiere una una verificación al domicilio y/o negocio",
    Avales: "Sin avales",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "No requiere de garantía Liquida",
    GarantiasPrendarias: "No aplican las garantías prendarias",
    TipoGarantia: "No requiere garantía quirografaria",
    Requisitos:
      "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial.",
    Cat: "Costo Anual Total Promedio (CAT) de 42.76% anual sin IVA. Para fines informativos y de comparación. fecha de cálculo 16/01/2024",
  },
  {
    id: `4`,
    name: "SOLI-TARJETA DORADA",
    type: "CREDITO",
    image: soliTarjetaDorada,
    description:
      "Para socios con excelente historial crediticio, plenamente identificados y autorizados por el Comité de Crédito",
    TasaOrdinaria: 22.0,
    TasaMoratoria: 48.0,
    MontoMinimo: 500,
    Montomaximo: 250000,
    PlazoMaximo: 96,
    FormasDepago:
      "Pago unico de capital e intereses (maximo 18 meses) y pagos periodicos de intereses y capital",
    BaseDeOtorgamiento: "Garantía liquida del 10%",
    Avales: "Sin avales",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "Si requiere de garantía Liquida",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    TipoGarantia: "Si requiere garantía quirografaria",
    Requisitos:
      "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "Costo Anual Total Promedio (CAT) de  24.37% anual sin IVA. Para fines informativos y de comparación. fecha de cálculo 16/01/2024",
  },
  {
    id: `5`,
    name: "SOLI-PRODUCTIVO",
    type: "CREDITO",
    image: soliProductivo,
    description:
      "Créditos destinados para la agricultura, ganadería y el comercio",
    TasaOrdinaria: 20.0,
    TasaMoratoria: 48.0,
    MontoMinimo: 2500,
    Montomaximo: 400000,
    PlazoMaximo: 96,
    FormasDepago:
      "Pago unico de capital e intereses (maximo 18 meses), pagos periodicos de intereses y capital",
    BaseDeOtorgamiento: "Se dejara en garantía liquida el 10%",
    Avales: "De 1 a 2 obligados solidarios (Avales)",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "Si requiere de garantía Liquida",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    TipoGarantia: "Si requiere garantía quirografaria",
    Requisitos:
      "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "Costo Anual Total Promedio (CAT) de  21.95% anual sin IVA. Para fines informativos y de comparación. fecha de cálculo 16/01/2024",
  },
  {
    id: `6`,
    name: "SOLI-VIVIENDA",
    type: "CREDITO",
    image: soliVivienda,
    description:
      "Destinados a créditos para la vivienda, como lo son las remodelaciones, mejoras, adquisición, etc",
    TasaOrdinaria: 21.6,
    TasaMoratoria: 48.0,
    MontoMinimo: 500,
    Montomaximo: 400000,
    PlazoMaximo: 96,
    FormasDepago:
      "Pago único de capital e intereses (máximo 18 meses), pagos periódicos de intereses y capital",
    BaseDeOtorgamiento: "Se dejara el 10% en garantía liquida",
    Avales: "De 1 a 2 obligados solidarios (Avales)",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "Si requiere de garantía Liquida",
    GarantiasPrendarias: "Solo si el comité lo solicita",
    TipoGarantia: "Si requiere garantía quirografaria",
    Requisitos:
      "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "Costo Anual Total Promedio (CAT) de  23.89% anual sin IVA. Para fines informativos y de comparación. fecha de cálculo 16/01/2024",
  },
  {
    id: `7`,
    name: "SOLI-HIPOTECARIO",
    type: "CREDITO",
    image: soliHipotecario,
    description:
      "Destinados para solventar cualquier necesidad de financiamiento dejando una garantía hipotecaria a favor de la cooperativa",
    TasaOrdinaria: 18.0,
    TasaMoratoria: 48.0,
    MontoMinimo: 300000,
    Montomaximo: 1000000,
    PlazoMaximo: 96,
    FormasDepago:
      "Pago único de capital e intereses (máximo 18 meses), pagos periódicos de intereses y capital",
    BaseDeOtorgamiento: "5% de garantía liquida",
    Avales:
      "Aval solo el cónyuge del solicitante o en caso de que exista un garante hipotecario seria también su cónyugue",
    GarantiaHipotecaria: "Valor comercial mínimo 3:1",
    GarantiasLiquidas: "5% de garantía liquida",
    GarantiasPrendarias: "No requiere de garantía prendaria",
    TipoGarantia: "No requiere garantía quirografaria",
    Requisitos:
      "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "Costo Anual Total Promedio (CAT) de  19.57% anual sin IVA. Para fines informativos y de comparación. fecha de cálculo 16/01/2024",
  },
  {
    id: `8`,
    name: "SOLI-AUTO",
    type: "CREDITO",
    image: soliAuto,
    description:
      "Exclusivo su uso en la compra de vehículos ya sean motocicletas y automóviles nuevos, adquiridas en agencias reconocidas por caja solidaria la huerta",
    TasaOrdinaria: 12.0,
    TasaMoratoria: 48.0,
    MontoMinimo: 5000,
    Montomaximo: 600000,
    PlazoMaximo: 72,
    FormasDepago: "Pago periódicos de capital e intereses",
    BaseDeOtorgamiento: "No requiere garantía liquida",
    Avales:
      "Es necesario un aval en el caso de la adquisición de motocicletas, para los automóviles solo en caso de que este casado agregar al conyuge",
    GarantiaHipotecaria: "No requiere de una garantía hipotecaria",
    GarantiasLiquidas: "No requiere garantía liquida",
    GarantiasPrendarias:
      "Si requiere garantía prendaria (factura del vehículo)",
    TipoGarantia: "Si requiere garantía quirografaria",
    Requisitos:
      "Comprobante de ingresos, comprobante de domicilio, Identificación Oficial",
    Cat: "Costo Anual Total Promedio (CAT) de  12.69% anual sin IVA. Para fines informativos y de comparación. fecha de cálculo 16/01/2024",
  },
];
//Codigo  para darle estilo a la tabla
const theme = createTheme({
  palette: {
    common: {
      black: "#7DB6EE",
      white: "#fff",
    },
    action: {
      hover: "#f5f5f5", //colo para el hover
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function CotizadorCredito() {
  //Configuracion para el alerta de calculo
  const [showAlert, setShowAlert] = useState(false);
  //Variable para controlar la renderizacion de algunos componentes mientras que no tenga un valor amount
  const [buttonPressed, setButtonPressed] = useState(false);
  //Ocultar el formControl cuando no este en vencimiento
  const [numeroSeleccionadoVencimiento, setNumeroSeleccionadoVencimiento] =
    useState(1);
  //Use state Para controlar cuando se agregue nuevo monto se borre todo.
  const [firstClick, setFirstClick] = useState(true);

  //use state para poder calcular la sumatoria de los intereses
  const [totalIntereses, setTotalIntereses] = useState(0);
  //use state para calcular la sumatoria de iva
  const [totalIVA, setTotalIVA] = useState(0);
  //Use state para calcular sumatoria del
  const [sumaAcumulada, setSumaAcumulada] = useState(0);

  //Codigo para colocar los filtros en el buscador
  const [amount, setAmount] = useState("");
  const [filteredCredits, setFilteredCredits] = useState([]);
  // Estado para controlar si se ha realizado una búsqueda
  const [searched, setSearched] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState(null);
  // Para el boton de mostra elementos despues de seleccionar uno
  const [showAll, setShowAll] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  //Calculo de la fecha limite de pago
  //Creacion de la tabla con  los datos capturados
  const [numeroSeleccionado, setNumeroSeleccionado] = useState(1);

  const [datosTabla, setDatosTabla] = useState([]);
  //para calcular los intereses diarios
  const calcularDiasSegunPeriodicidad = (periodicidad) => {
    switch (parseInt(periodicidad, 10)) {
      case 1: // Quincenales
        return 15;
      case 2: // Mensuales
        return 30;
      case 3: // Bimestrales
        return 60;
      case 4: // Trimestrales
        return 90;
      case 5: // Semestrales
        return 180;
      case 6: // Anuales
        return 360;
      case 7: // Vencimiento
        return 30; // porque calcula en meses
      default:
        return 0; // Valor predeterminado en caso de periodicidad no reconocida
    }
  };

  //Calculo de las mensualidades en base a los datos
  const [fechaInicio, setFechaInicio] = useState(new Date());

  // Función para calcular la fecha de pago teniendo en cuenta los días feriados y fines de semana
  const calcularFechaPago = (
    fechaInicio,
    plazo,
    numeroSeleccionadoVencimiento
  ) => {
    if (isNaN(numeroSeleccionadoVencimiento)) {
      return null; // Devuelve null si el número no es válido
    }

    let fechaCalculada = new Date(fechaInicio);
    fechaCalculada = addDays(fechaCalculada, 1); // Ajusta la fecha de inicio al día siguiente

    const numeroMesesVencimiento = parseInt(numeroSeleccionadoVencimiento, 10);

    // Calcula el número de días que abarcará el cálculo
    let diasCalculo;
    if (numeroMesesVencimiento !== 7) {
      switch (numeroMesesVencimiento) {
        case 1: 
          diasCalculo = plazo * 15;
          break;
        case 2: 
          diasCalculo = plazo * 30; 
          break;
        case 3: 
          diasCalculo = plazo * 60; 
          break;
        case 4: 
          diasCalculo = plazo * 90; 
          break;
        case 5: 
          diasCalculo = plazo * 180; 
          break;
        case 6: 
          diasCalculo = plazo * 360; 
          break;
        default:
          return null;
      }
    } else {
      diasCalculo = plazo * 30;
    }

    // Ajusta la fecha según la periodicidad
    switch (numeroMesesVencimiento) {
      case 1: // Quincenales
        return addDays(fechaCalculada, diasCalculo);
      case 2: // Mensuales
      case 3: // Bimestrales
      case 4: // Trimestrales
      case 5: // Semestrales
      case 6: // Anuales
      case 7: // Vencimiento
        let fechaFinal = addMonths(fechaCalculada, plazo);
        // Si la fecha final cae en fin de semana, ajusta al siguiente día hábil
        while (isWeekend(fechaFinal)) {
          fechaFinal = addDays(fechaFinal, 1);
        }
        return fechaFinal;
      default:
        return null;
    }
  };

  //metodo para calcular la fecha cuando es por vencimiento
  const calcularFechaPagoVencimiento = (
    fechaInicio,
    numeroSeleccionadoVencimiento
  ) => {
    if (isNaN(numeroSeleccionadoVencimiento)) {
      return null; // Devuelve null si el número no es válido
    }
    const numeroMesesVencimiento = parseInt(numeroSeleccionadoVencimiento, 10);

    // Calcula la fecha en base al número de meses
    return addMonths(fechaInicio, numeroMesesVencimiento);
  };

  //Metodo para manejar el envio de datos es pago fijo o pago que baja su valor
  const CalcularPagoFijoDescendiente = (codigo) => {
    // Aquí es donde decides qué hacer con base en el código
    if (codigo === "SOLI-EXPRES" || codigo === "SOLI-AUTO") {
      // Si el código es SOLI-EXPRES o SOLI-AUTO, llamas a la función crearCamposDeTablaPagosFijos
      crearCamposDeTablaPagosFijos();
      setShowAlert(true);
    } else {
      // Para otros códigos, puedes llamar a la función crearCamposDeTabla
      crearCamposDeTabla();
      setShowAlert(true);
    }
  };

  //para crear las tablas
  const crearCamposDeTabla = () => {
    const nuevasFilas = [];
    let totalInteresesAcumulado = 0;
    let totalIVAAcumulado = 0;
    let sumaAcumulada = 0;

    let saldoInsoluto = amount; // Inicializa el saldo insoluto con el monto inicial
    if (periodicidad === "7") {
      const fila = [];
      const abonoCapitalVencimiento = amount;
      const numeroMesesVencimiento = parseInt(
        numeroSeleccionadoVencimiento,
        10
      );
      const fechaInicio = new Date();
      const fechaLimitePago = calcularFechaPagoVencimiento(
        fechaInicio,
        numeroMesesVencimiento
      );

      let fechaFormateada = "";

      if (fechaLimitePago !== null) {
        fechaFormateada = fechaLimitePago.toLocaleDateString("es-MX"); // Aquí asignamos la fecha formateada
      } else {
        console.log("fechaLimitePago es null");
      }

      const tasaAnual = selectedCredit.TasaOrdinaria;
      const tasaDiaria = tasaAnual / 100 / 360;

      // Calcula el interés total
      const interesVencimiento =
        saldoInsoluto * tasaDiaria * 30 * numeroMesesVencimiento;
      const interesVencimientoFormateados = interesVencimiento.toLocaleString(
        "es-MX",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true,
        }
      );
      const iva = interesVencimiento * 0.16;
      const ivaFormateado = iva.toLocaleString("es-MX", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
      });
      const abonoVencimiento =
        interesVencimiento + iva + abonoCapitalVencimiento;
      const abonoVencimientoFormateado = abonoVencimiento.toLocaleString(
        "es-MX",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true,
        }
      );

      for (let j = 1; j <= 7; j++) {
        switch (j) {
          case 1:
            fila.push(`1`);
            break;
          case 2:
            fila.push(fechaFormateada);
            break;
          case 3:
            const abonoCapitalFormateado =
              abonoCapitalVencimiento.toLocaleString("es-MX", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
              });

            fila.push(`$ ${abonoCapitalFormateado}`);
            break;
          case 4:
            fila.push(`$ ${interesVencimientoFormateados}`);
            totalInteresesAcumulado = interesVencimientoFormateados;
            break;
          case 5:
            fila.push(`$ ${ivaFormateado}`);
            totalIVAAcumulado = ivaFormateado;
            break;
          case 6:
            fila.push(`$ ${abonoVencimientoFormateado}`);
            sumaAcumulada = abonoVencimientoFormateado;
            break;
          case 7:
            const saldoInsolutoFormateado = saldoInsoluto.toLocaleString(
              "es-MX",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
              }
            );
            fila.push(`$ ${saldoInsolutoFormateado}`);
            break;
          default:
            console.log("Valor no reconocido:", j);
        }
        setTotalIntereses(totalInteresesAcumulado);
        setTotalIVA(totalIVAAcumulado);
        setSumaAcumulada(sumaAcumulada);
      }
      nuevasFilas.push(fila);
    } else {
      // Si la periodicidad no es "Vencimiento", realizar cálculos normales
      for (let i = 1; i <= numeroSeleccionado; i++) {
        const fila = [];
        const fechaLimitePago = calcularFechaPago(fechaInicio, i, periodicidad);
        const fechaFormateada = fechaLimitePago.toLocaleDateString("es-MX");
        const dias = calcularDiasSegunPeriodicidad(periodicidad);
        const abonoCapital = amount / numeroSeleccionado;
        const tasaAnual = selectedCredit.TasaOrdinaria;
        const interesOrdinario =
          ((saldoInsoluto * (tasaAnual / 100)) / 360) * dias;
        const iva = interesOrdinario * 0.16;
        const abonoCapitalesFormateado = abonoCapital.toLocaleString("es-MX", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true,
        });

        //para agarrar el formato sin comas
        const abonoCapitalesFormateadoSinComas = abonoCapital.toLocaleString(
          "es-MX",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: false,
          }
        );

        const residuoPago =
          amount -
          parseFloat(abonoCapitalesFormateadoSinComas) * numeroSeleccionado;

        const interesOrdinarioFormateado = interesOrdinario.toLocaleString(
          "es-MX",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true,
          }
        );
        const ivaFormateado = iva.toLocaleString("es-MX", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true,
        });
        const totalAPagar = abonoCapital + interesOrdinario + iva;
        const totalAPagarFormateado = totalAPagar.toLocaleString("es-MX", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true,
        });
        const saldoInsolutoFormateado = saldoInsoluto.toLocaleString("es-MX", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true,
        });

        fila.push(`${i}`);
        fila.push(fechaFormateada);
        // En la última iteración, sumamos el residuo al abono a capital
        if (i >= numeroSeleccionado && residuoPago > 0) {
          fila.push(
            `$ ${(abonoCapital + residuoPago).toLocaleString("es-MX", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              useGrouping: true,
            })}`
          );
        } else {
          fila.push(`$ ${abonoCapitalesFormateado}`);
        }
        fila.push(`$ ${interesOrdinarioFormateado}`);
        fila.push(`$ ${ivaFormateado}`);
        fila.push(`$ ${totalAPagarFormateado}`);
        fila.push(`$ ${saldoInsolutoFormateado}`);

        nuevasFilas.push(fila);
        totalInteresesAcumulado += interesOrdinario;
        totalIVAAcumulado += iva;
        sumaAcumulada += totalAPagar;
        saldoInsoluto -= abonoCapital;
      }
      // Actualizar los estados al final del bucle
      setTotalIntereses(totalInteresesAcumulado);
      setTotalIVA(totalIVAAcumulado);
      setSumaAcumulada(sumaAcumulada);
    }
    setButtonPressed(true);
    setDatosTabla(nuevasFilas);
  };

  //para crear las tablas de pagos fijos
  //soli-express y soli-auto pertenecen a estas dos tipos de credit
  const crearCamposDeTablaPagosFijos = () => {
    const nuevasFilas = [];
    let cuota;
    let totalInteresesAcumulado = 0;
    let totalIVAAcumulado = 0;
    let sumaAcumulada = 0;
    let saldoInsoluto = amount; // Inicializa el saldo insoluto con el monto inicial
    //variables para calcular el  interes
    let AbonoCapitalPagoFijo;
    //Para calcular el saldo insoluto
    let saldoInsolutoPagoFijo = amount;
    // Si la periodicidad no es "Vencimiento", realizar cálculos normales
    for (let i = 1; i <= numeroSeleccionado; i++) {
      const fila = [];
      const abonoCapital = amount / numeroSeleccionado;
      const tasaAnual = selectedCredit.TasaOrdinaria;
      for (let j = 1; j <= 7; j++) {
        switch (j) {
          case 1:
            fila.push(`${i}`);
            break;
          case 2:
            const fechaLimitePago = calcularFechaPago(
              fechaInicio,
              i,
              periodicidad
            );
            const fechaFormateada = fechaLimitePago.toLocaleDateString("es-MX");
            fila.push(fechaFormateada);

            break;
          case 3:
            //Calculo  del  Abono a capital
            const pTasa = {
              1: 24,
              2: 12,
              3: 6,
              4: 4,
              5: 2,
            };

            const tasIVA = 0.16; // Tasa de IVA al 16%

            let cuotaCalculadapagoFijo; // Variable para almacenar el valor de la cuota
            let tMensual;
            if (periodicidad in pTasa) {
              tMensual = tasaAnual / 100 / pTasa[periodicidad];

              // Realiza el cálculo de la cuota con la tasa mensual y el IVA.
              const tasaMensualConIVA = tMensual * (1 + tasIVA);
              cuotaCalculadapagoFijo =
                (amount * tasaMensualConIVA) /
                (1 -
                  Math.pow(
                    1 + tasaMensualConIVA,
                    -Math.abs(numeroSeleccionado)
                  ));
            } else {
              fila.push("Errores en los cálculos...");
            }
            //Calculo del iva
            const tasasMensualCalc = tasaAnual / 100 / pTasa[periodicidad];
            let interesPagofijoIvaCalc =
              saldoInsolutoPagoFijo * tasasMensualCalc;
            const ivaPagoFijoCalc = interesPagofijoIvaCalc * 0.16;

            //calculo de interes
            const tasasMensualesPagoFijo =
              tasaAnual / 100 / pTasa[periodicidad];
            let interesesPagofijo =
              saldoInsolutoPagoFijo * tasasMensualesPagoFijo;
            AbonoCapitalPagoFijo =
              cuotaCalculadapagoFijo - ivaPagoFijoCalc - interesesPagofijo;

            const AbonoCapitalPagoFijoFormateado =
              AbonoCapitalPagoFijo.toLocaleString("es-MX", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
              });
            sumaAcumulada += AbonoCapitalPagoFijo;
            fila.push(`$ ${AbonoCapitalPagoFijoFormateado}`);

            break;
          case 4:
            const periodicidadTasa = {
              1: 24,
              2: 12,
              3: 6,
              4: 4,
              5: 2,
            };
            const tasaMensual =
              tasaAnual / 100 / periodicidadTasa[periodicidad];
            let interesPagofijo = saldoInsolutoPagoFijo * tasaMensual;

            const interesPagofijoFormateado = interesPagofijo.toLocaleString(
              "es-MX",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
              }
            );
            totalInteresesAcumulado += interesPagofijo;
            fila.push(`$ ${interesPagofijoFormateado}`);
            break;
          case 5:
            const periodicidadTasas = {
              1: 24,
              2: 12,
              3: 6,
              4: 4,
              5: 2,
            };
            const tasasMensual =
              tasaAnual / 100 / periodicidadTasas[periodicidad];
            let interesPagofijoIva = saldoInsolutoPagoFijo * tasasMensual;

            const ivaPagoFijo = interesPagofijoIva * 0.16;
            const ivapagoFijoFormateado = ivaPagoFijo.toLocaleString("es-MX", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              useGrouping: true,
            });
            totalIVAAcumulado += ivaPagoFijo;
            fila.push(`$ ${ivapagoFijoFormateado}`);
            // Actualizar el total de IVA en cada iteración

            break;
          case 6:
            const periodicidadToTasa = {
              1: 24,
              2: 12,
              3: 6,
              4: 4,
              5: 2,
            };

            const tasaIVA = 0.16; // Tasa de IVA al 16%

            if (periodicidad in periodicidadToTasa) {
              const tasaMensual =
                tasaAnual / 100 / periodicidadToTasa[periodicidad];

              // Realiza el cálculo de la cuota con la tasa mensual y el IVA.
              const tasaMensualConIVA = tasaMensual * (1 + tasaIVA);
              cuota =
                (amount * tasaMensualConIVA) /
                (1 -
                  Math.pow(
                    1 + tasaMensualConIVA,
                    -Math.abs(numeroSeleccionado)
                  ));

              // Formatea la cuota en formato "es-MX".
              const cuotaFormateada = cuota.toLocaleString("es-MX", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
              });
              // Calculo de la  sumatoria de los
              const sumAc = cuota * numeroSeleccionado;
              sumaAcumulada = sumAc;

              fila.push(`$ ${cuotaFormateada}`);
            } else {
              fila.push("Errores en los cálculos...");
            }

            break;

          case 7:
            saldoInsolutoPagoFijo -= AbonoCapitalPagoFijo;
            saldoInsolutoPagoFijo = Math.max(0, saldoInsolutoPagoFijo);
            const saldoInsolutoFormateado =
              saldoInsolutoPagoFijo.toLocaleString("es-MX", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
              });
            fila.push(`$ ${saldoInsolutoFormateado}`);
            break;
          default:
            console.log(" Error Valor no reconocido:", j);
        }
      }
      nuevasFilas.push(fila);
      saldoInsoluto -= abonoCapital;
    }
    setButtonPressed(true);
    setDatosTabla(nuevasFilas);
    setTotalIntereses(totalInteresesAcumulado);
    setTotalIVA(totalIVAAcumulado);
    setSumaAcumulada(sumaAcumulada);
  };

  // Función para calcular los plazos de pago basados en la periodicidad y PlazoMaximo
  const [periodicidad, setPeriodicidad] = useState(1);
  const calcularPlazos = (periodicidad, plazosMaximo) => {
    switch (parseInt(periodicidad, 10)) {
      case 1: // Quincenales
        return Array.from({ length: plazosMaximo * 2 }, (_, i) => i + 1);
      case 2: // Mensuales
        return Array.from({ length: plazosMaximo }, (_, i) => i + 1);
      case 3: // Bimestrales
        return Array.from({ length: plazosMaximo / 2 }, (_, i) => i + 1);
      case 4: // Trimestrales
        return Array.from({ length: plazosMaximo / 3 }, (_, i) => i + 1);
      case 5: // Semestrales
        return Array.from({ length: plazosMaximo / 6 }, (_, i) => i + 1);
      case 6: // Anuales
        return Array.from({ length: plazosMaximo / 12 }, (_, i) => i + 1);
      case 7: // Vencimiento
        return Array.from({ length: plazosMaximo }, (_, i) => i + 1);
      default:
        return [];
    }
  };

  // Obtén el PlazoMaximo del producto seleccionado
  const plazoMaximoSeleccionado = selectedCredit
    ? selectedCredit.PlazoMaximo
    : 0;
  // Calcula los plazos en función de la periodicidad y el PlazoMaximo
  const plazos = calcularPlazos(periodicidad, plazoMaximoSeleccionado);

  //Filtrar creditos que no cumplen con el rango
  const filterCredits = () => {
    const filtered = credit.filter(
      (option) => option.MontoMinimo <= amount && option.Montomaximo >= amount
    );
    setFilteredCredits(filtered);
    setSearched(true); // Marcar que se ha realizado una búsqueda
  };
  useEffect(() => {
    // Restablecer el estado "searched" al cargar la página
    setSearched(false);
  }, []);
  //Codigo para darle formato a la tabla
  const amountFormatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  });

  //Variable para formatear a MXN
  const formattedMexicanCash = amount.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  //Calculo para cuando se dos click
  const reloadPage = () => {
    if (amount !== "" && !firstClick) {
      // Recargar la página solo si el TextField no está vacío y ya se hizo clic una vez
      window.location.reload();
    } else {
      // Permitir el ingreso del monto en el primer clic o si el TextField está vacío
      setFirstClick(false);
    }
  };

  //Como para hacer que la alerta desaparezca
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <ButtonArrow />
      <Grid
        container
        sx={{
          alignItems: "center", // Alinea el texto y la imagen en el centro verticalmente
          justifyContent: "flex-start", // Alinea la imagen a la izquierda
        }}
        style={{
          backgroundImage: `url(${imgRibbon})`, 
          backgroundSize: "cover", 
          backgroundRepeat: "no-repeat", 
        }}
      >
        <Grid item xs={4} sx={{ display: "flex" }}>
          <Grow in={true} timeout={{ enter: 4000, exit: 4000 }}>
            <img
              src={imgRibbonFigure}
              alt={imgRibbonFigure}
              style={{
                maxWidth: "45%",
                filter: "brightness(0) invert(1)", // Cambia el color de la imagen a blanco
                transform: "rotate(0deg)", // Gira la imagen 180 grados
              }}
            />
          </Grow>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: "flex", justifyContent: "center" }}
          className="imprimir-contenido"
        >
          <Typography
            variant="h1"
            color="white"
            sx={{
              fontSize: {
                // Haciendo responsivo los textos
                xs: "2.3rem", // Tamaño de fuente para pantallas pequeñas
                sm: "5.3rem", // Tamaño de fuente para pantallas medianas y más grandes
              },
            }}
          >
            SIMULADOR
          </Typography>
          <style>
            {`
          @keyframes pulsate {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
          }
        `}
          </style>
        </Grid>
      </Grid>

      <Typography
        variant="h5"
        align="center"
        color="#0C213D"
        sx={{ marginTop: 5 }}
        className="imprimir-contenido"
      >
        !Crédito a medida de tus¡
      </Typography>
      <Typography
        variant="h3"
        align="center"
        color="#0C213D"
        sx={{ marginTop: 0, mb: 3 }}
        className="imprimir-contenido"
      >
        <strong>NECESIDADES</strong>
      </Typography>

      <Grid container spacing={0}>
        <Grid item xs={12} md={5} sx={{ mb: 5 }}>
          <Grid container direction="column" alignItems="center">
            <TextField
              label="Ingresa el monto"
              name="monto"
              required
              sx={{ width: "45%" }}
              type="number"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onClick={reloadPage}
              value={amount}
              onChange={(e) => setAmount(parseFloat(e.target.value))}
            />
            <Typography
              variant="caption"
              sx={{ fontWeight: "light", opacity: 0.7 }}
            >
              Cifra en pesos mexicanos
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={filterCredits}
              sx={{ width: "40%", mt: "7px" }}
            >
              CONSULTAR
            </Button>

            <TableContainer sx={{ maxWidth: "75%" }}>
              {searched && filteredCredits.length === 0 ? (
                <Typography
                  variant="body1"
                  sx={{ marginTop: 2, marginLeft: 2 }}
                  align="center"
                >
                  Coloca un monto válido y calcula tu préstamo.
                </Typography>
              ) : (
                <Table>
                  {searched && filteredCredits.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Nombre
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Monto Máximo
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {filteredCredits.map(
                      (row) =>
                        (showAll || selectedRow === row.id) && (
                          <TableRow
                            key={row.id}
                            onClick={() => {
                              setSelectedRow(row.id);
                              setSelectedCredit(row);
                              setShowAll(false);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="center">
                              {amountFormatter.format(
                                parseFloat(row.Montomaximo)
                              )}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                    {!showAll && (
                      <TableRow>
                        <TableCell sx={{ borderBottom: 0 }}>
                          <Button
                            variant="contained"
                            onClick={() => setShowAll(true)}
                            sx={{
                              backgroundColor: "#ffffff", // Fondo blanco
                              padding: "0", // Eliminar todo el relleno
                              minWidth: "auto", // Permitir que el botón se encoja
                              border: "none",
                              boxShadow: "none",
                              marginTop: "8px",
                              color: "#ffffff",
                              "&:hover": {
                                backgroundColor: "#ffffff", // Cambiar el color de fondo en hover a un gris más oscuro
                              },
                            }}
                          >
                            <ExpandMoreIcon
                              style={{
                                fontSize: "45px",
                                alignSelf: "center",
                                color: "#0C213D",
                              }}
                            />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>

            <Grid item xs={12} md={7} sx={{ mb: 4 }}>
              {/*Informacion detallada del credito*/}
              {selectedCredit ? (
                <Grid container direction="column" alignItems="flex-start">
                  {/* Desplegable de Periodicidad */}
                  <Grid container justifyContent="center" sx={{ marginTop: 1 }}>
                    <FormControl
                      sx={{
                        marginLeft: 7,
                        marginRight: 7,
                        marginTop: 6,
                        width: { xs: "75%", sm: "70%" },
                      }}
                    >
                      <InputLabel htmlFor="periodicidad">
                        Periodicidad
                      </InputLabel>
                      <Select
                        native
                        label="Periodicidad"
                        inputProps={{
                          name: "periodicidad",
                          id: "periodicidad",
                        }}
                        value={periodicidad}
                        onChange={(e) => setPeriodicidad(e.target.value)}
                      >
                        <option value={1}>Quincenales</option>
                        <option value={2}>Mensuales</option>
                        <option value={3}>Bimestrales</option>
                        <option value={4}>Trimestrales</option>
                        <option value={5}>Semestrales</option>
                        {selectedCredit.name !== "SOLI-EXPRES" &&
                        selectedCredit.name !== "SOLI-AUTO" ? (
                          <option value={6}>Anuales</option>
                        ) : null}
                        {selectedCredit.name !== "SOLI-EXPRES" &&
                        selectedCredit.name !== "SOLI-AUTO" ? (
                          <option value={7}>Vencimiento</option>
                        ) : null}
                      </Select>
                    </FormControl>
                    <FormControl
                      required
                      sx={{
                        marginLeft: 7,
                        marginRight: 7,
                        marginTop: 4,
                        width: { xs: "75%", sm: "70%" },
                      }}
                    >
                      <InputLabel htmlFor="plazosPago">
                        Plazos de Pago
                      </InputLabel>
                      <Select
                        native
                        label="Plazos de Pago"
                        inputProps={{
                          name: "plazosPago",
                          id: "plazosPago",
                        }}
                        value={periodicidad === "7" ? 1 : numeroSeleccionado} // Establece el valor a '1' cuando la periodicidad es "Vencimiento"
                        onChange={(e) => setNumeroSeleccionado(e.target.value)}
                        disabled={periodicidad === "7"} // Deshabilita la selección cuando la periodicidad es "Vencimiento"
                      >
                        {plazos.map((plazo) => (
                          <option key={plazo} value={plazo}>
                            {plazo}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    {periodicidad === "7" && ( // Solo muestra este FormControl cuando la periodicidad es "Vencimiento"
                      <>
                        <Typography
                          sx={{
                            marginLeft: 7,
                            marginRight: 7,
                            marginTop: 2,
                            width: { xs: "60%", sm: "65%" },
                          }}
                          variant="body"
                        >
                          Periodo de liquidación
                        </Typography>
                        <FormControl
                          sx={{ width: { xs: "75%", sm: "70%" }, marginTop: 2 }}
                        >
                          <InputLabel htmlFor="plazosPago">
                            Mensualidades
                          </InputLabel>
                          <Select
                            native
                            label="Vencimiento"
                            value={numeroSeleccionadoVencimiento}
                            onChange={(e) =>
                              setNumeroSeleccionadoVencimiento(e.target.value)
                            }
                          >
                            {Array.from({ length: 18 }, (_, i) => i + 1).map(
                              (numero) => (
                                <option key={numero} value={numero}>
                                  {numero}
                                </option>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </>
                    )}

                    <Grid
                      item
                      xs={7}
                      sm={7}
                      md={7}
                      sx={{ textAlign: "center", mt: 4 }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#1976D2",
                          color: "#ffffff",
                          width: { xs: "100%", sm: "auto" },
                        }}
                        onClick={() =>
                          CalcularPagoFijoDescendiente(selectedCredit.name)
                        }
                      >
                        Calcular cotización
                      </Button>
                      {showAlert && (
                        <Alert
                          severity="success"
                          onClose={() => setShowAlert(false)}
                          sx={{ mt: 2 }}
                        >
                          <AlertTitle>Simulación calculada</AlertTitle>
                          <strong>¡Desliza hacia abajo!</strong>
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}{" "}
              {/*Aqui se agrega  una validación si se */}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          sx={{ mb: 5 }}
          className="imprimir-contenido imprimir-detalles"
        >
          {/*Informacion detallada del credito*/}
          {selectedCredit ? (
            <Grid container direction="column" alignItems="flex-start">
              {/*Componente para colocar la imagen de cada tipode prestamo*/}
              <Box
                component="img"
                src={selectedCredit.image}
                alt="Descripción de la imagen"
                sx={{
                  width: { xs: "50%", sm: "25%" }, // Establece el ancho de la imagen al 50% en pantallas pequeñas y al 23% en pantallas más grandes
                  height: "auto", // Asegura que la imagen mantenga su relación de aspecto
                  maxWidth: "100%", // Asegura que la imagen sea responsiva
                  marginLeft: { xs: "auto", sm: "0" }, // Centra la imagen en pantallas pequeñas
                  marginRight: { xs: "auto", sm: "0" }, // Centra la imagen en pantallas pequeñas
                }}
              />
              <Typography color={"#0C213D"} variant="h4" ml={3} mr={2} mt={1}>
                Detalles del Crédito
              </Typography>
              <Typography
                color={"#1976D2"}
                variant="body1"
                ml={3}
                mr={2}
                mt={1}
              >
                {selectedCredit.name}
              </Typography>
              <Typography
                color={"#0C213D"}
                variant="subtitle2"
                ml={3}
                mr={4}
                mt={1}
              >
                {selectedCredit.description}
              </Typography>
              <Typography
                color={"#0C213D"}
                variant="subtitle2"
                ml={3}
                marginRight={2}
                mt={1}
              >
                {selectedCredit.Avales}
              </Typography>
              <Typography color={"#0C213D"} variant="subtitle2" ml={3} mt={1}>
                Monto Minimo de{" "}
                {amountFormatter.format(parseFloat(selectedCredit.MontoMinimo))}{" "}
                hasta un Máximo de:{" "}
                {amountFormatter.format(parseFloat(selectedCredit.Montomaximo))}
              </Typography>
              <Typography color={"#0C213D"} variant="subtitle2" ml={3} mt={1}>
                Tasa de interés ordinaria:{selectedCredit.TasaOrdinaria}% Anual
              </Typography>
              <Typography color={"#0C213D"} variant="subtitle2" ml={3} mt={1}>
                Plazo maximo: {selectedCredit.PlazoMaximo} Mensualidades
              </Typography>
              <Typography color={"#0C213D"} variant="subtitle2" ml={3} mt={1}>
                {selectedCredit.Cat}
              </Typography>
              <Typography color={"#0C213D"} variant="subtitle2" ml={3} mt={1}>
                Requisitos: {selectedCredit.Requisitos}
              </Typography>
              {/* Desplegable de Periodicidad */}
            </Grid>
          ) : null}
        </Grid>

        <Grid container justifyContent="center" sx={{ marginTop: 4 }}>
          {buttonPressed && amount !== "" && (
            <Grid
              container
              justifyContent="center"
              sx={{ marginTop: 4 }}
              className="imprimir-contenido imprimir-date"
            >
              <Grid
                container
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "center", sm: "flex-end" }}
                alignItems="center"
                mb={1}
              >
                <Grid item xs={12} sm="auto">
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", mr: 3 }}
                  >
                    Cantidad: {formattedMexicanCash}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", mr: 12 }}
                  >
                    Fecha de cálculo: {format(new Date(), "dd/MM/yyyy")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/*Tabla de amortizaciones*/}
          <TableContainer
            component={Paper}
            sx={{
              marginX: {
                xs: "15px auto",
                lg: "60px auto",
              },
              maxWidth: {
                xs: "calc(100% - 10px)",
                lg: "calc(100% - 140px)",
              },
            }}
            className="imprimir-contenido imprimir-table"
          >
            <Table>
              {datosTabla.length > 0 && (
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      theme={theme}
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      No. ABONO
                    </StyledTableCell>
                    <StyledTableCell
                      theme={theme}
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      FECHA LÍMITE DE PAGO
                    </StyledTableCell>
                    <StyledTableCell
                      theme={theme}
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      ABONO CAPITAL
                    </StyledTableCell>
                    <StyledTableCell
                      theme={theme}
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      INTERÉS ORDINARIO
                    </StyledTableCell>
                    <StyledTableCell
                      theme={theme}
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      IVA
                    </StyledTableCell>
                    <StyledTableCell
                      theme={theme}
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      TOTAL A PAGAR
                    </StyledTableCell>
                    <StyledTableCell
                      theme={theme}
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      SALDOS INSOLUTOS DE CAPITAL
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {datosTabla.map((fila, index) => (
                  <StyledTableRow theme={theme} key={index}>
                    {fila.map((dato, subIndex) => (
                      <TableCell
                        key={subIndex}
                        sx={
                          subIndex === 0 ||
                          subIndex === 1 ||
                          subIndex === 2 ||
                          subIndex === 3 ||
                          subIndex === 4 ||
                          subIndex === 5 ||
                          subIndex === 6
                            ? { textAlign: "center" }
                            : null
                        }
                      >
                        {dato}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ))}
                {buttonPressed && amount !== "" && (
                  <StyledTableRow theme={theme}>
                    <TableCell
                      colSpan={2}
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Totales
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {formattedMexicanCash}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {totalIntereses.toLocaleString("es-MX", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                      })}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {totalIVA.toLocaleString("es-MX", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                      })}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {" "}
                      {sumaAcumulada.toLocaleString("es-MX", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                      })}
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {datosTabla.length > 0 && (
            <Grid
              item
              xs={12}
              container
              direction="column"
              alignItems="flex-start"
              sx={{ marginX: { xs: 1, sm: 10 } }}
              marginTop={3}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#0C213D",
                  color: "#FFFFFF",
                  marginBottom: "10px",
                }}
                onClick={() => window.print()} // Agrega la función de imprimir al evento onClick
              >
                Imprimir
              </Button>
              <Typography
                variant="caption"
                mb={1}
                className="imprimir-contenido imprimir-text"
              >
                En la cotización anterior, Todos los montos están establecidos
                en pesos mexicanos (<strong>MXN</strong>).
              </Typography>
              <Typography
                variant="caption"
                mb={3}
                className="imprimir-contenido imprimir-text-1"
              >
                Esta información tiene un carácter puramente ilustrativo. Por lo
                tanto, el usuario debe comprender que cualquier información{" "}
                <br />
                obtenida no constituye una solicitud de crédito, ni implica
                ninguna obligación para{" "}
                <strong>Caja Solidaria La Huerta</strong>.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default CotizadorCredito;

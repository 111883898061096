import React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";

//Imagenes para el modal confianza
import InterCambio from "../../../img/Services/Remesas/Modal/Confianza/interCambio.png";
import MoneyGram from "../../../img/Services/Remesas/Modal/Confianza/MoneyGram.png";
import OMnex from "../../../img/Services/Remesas/Modal/Confianza/OMnex.png";
import Sigue from "../../../img/Services/Remesas/Modal/Confianza/Sigue.png";
import TransFast from "../../../img/Services/Remesas/Modal/Confianza/transfast.png";
import UniTeller from "../../../img/Services/Remesas/Modal/Confianza/UniTeller.png";
import ViAmericas from "../../../img/Services/Remesas/Modal/Confianza/ViAmericas.png";

//Imagenes para el modal AirPak
import AirPAk from "../../../img/Services/Remesas/Modal/AirPak/Airpak.png";
import Barri from "../../../img/Services/Remesas/Modal/AirPak/Barri.png";
import DolEx from "../../../img/Services/Remesas/Modal/AirPak/DolEx.png";
import EnraMex from "../../../img/Services/Remesas/Modal/AirPak/EnraMex.png";
import Envialana from "../../../img/Services/Remesas/Modal/AirPak/EnviaLana.png";
import InterCambio2 from "../../../img/Services/Remesas/Modal/AirPak/Intercambio.png";
import MoneyGram2 from "../../../img/Services/Remesas/Modal/AirPak/MoneyGram.png";
import OrderExpress from "../../../img/Services/Remesas/Modal/AirPak/OrderExpress.png";
import OrlandiValuta from "../../../img/Services/Remesas/Modal/AirPak/OrlandiValuta.png";
import Ria from "../../../img/Services/Remesas/Modal/AirPak/Ria.png";
import Vigo from "../../../img/Services/Remesas/Modal/AirPak/Vigo.png";
import WesternOnion from "../../../img/Services/Remesas/Modal/AirPak/westernOnion.png";
import WorldRemit from "../../../img/Services/Remesas/Modal/AirPak/worldremit.png";

//Imagenes para el modal Intermex
import Choice from "../../../img/Services/Remesas/Modal/Intermex/choice.png";
import DelgadoTravel from "../../../img/Services/Remesas/Modal/Intermex/DelgadoTravel.png";
import DinEx from "../../../img/Services/Remesas/Modal/Intermex/DinEx.png";
import Enramex from "../../../img/Services/Remesas/Modal/Intermex/EnraMex.png";
import GiroMex from "../../../img/Services/Remesas/Modal/Intermex/GiroMex.png";
import GiroSol from "../../../img/Services/Remesas/Modal/Intermex/GiroSol.png";
import GoldenMoney from "../../../img/Services/Remesas/Modal/Intermex/GoldenMoney.png";
import GrouPex from "../../../img/Services/Remesas/Modal/Intermex/GrouPex.png";
import IDT from "../../../img/Services/Remesas/Modal/Intermex/IDT.png";
import InterMex from "../../../img/Services/Remesas/Modal/Intermex/Intermex.png";
import LaNacional from "../../../img/Services/Remesas/Modal/Intermex/LaNacional.png";
import MaxiMs from "../../../img/Services/Remesas/Modal/Intermex/Maxims.png";
import Omnex from "../../../img/Services/Remesas/Modal/Intermex/Omnex.png";
import Remitly from "../../../img/Services/Remesas/Modal/Intermex/Remitly.png";
import SmallWorld from "../../../img/Services/Remesas/Modal/Intermex/SmallWorld.png";
import SouthExchangue from "../../../img/Services/Remesas/Modal/Intermex/SouthExchangue.png";
import Titanes from "../../../img/Services/Remesas/Modal/Intermex/Titanes.png";
import Xoom from "../../../img/Services/Remesas/Modal/Intermex/Xoom.png";

const dataIntermex = [
  { title: "DinEx", id: 4, image: DinEx },
  { title: "DolEx", id: 5, image: DolEx },
  { title: "Money Gram", id: 16, image: MoneyGram },
  { title: "EnraMex", id: 6, image: Enramex },
  { title: "GiroMex", id: 7, image: GiroMex },
  { title: "Delgado travel", id: 3, image: DelgadoTravel },
  { title: "GIROSOL", id: 8, image: GiroSol },
  { title: "Golden Money", id: 9, image: GoldenMoney },
  { title: "GrouPex", id: 10, image: GrouPex },
  { title: "Order Express", id: 18, image: OrderExpress },
  { title: "Remitly", id: 19, image: Remitly },
  { title: "InterCambio", id: 12, image: InterCambio },
  { title: "Intermex", id: 13, image: InterMex },
  { title: "Ria", id: 20, image: Ria },
  { title: "Maxi-ms", id: 15, image: MaxiMs },
  { title: "Omnex", id: 17, image: Omnex },
  { title: "Uniteller", id: 24, image: UniTeller },
  { title: "La Nacional", id: 14, image: LaNacional },
  { title: "Small World", id: 21, image: SmallWorld },
  { title: "South Exchangue", id: 22, image: SouthExchangue },
  { title: "Titanes", id: 23, image: Titanes },
  { title: "IDT", id: 11, image: IDT },
  { title: "BARRI", id: 1, image: Barri },
  { title: "Choice", id: 2, image: Choice },
  { title: "Xoom", id: 25, image: Xoom },
];

const dataAirpak = [
  { title: "AirPak", id: 26, image: AirPAk },
  { title: "Vigo", id: 27, image: Vigo },
  { title: "DolEx", id: 28, image: DolEx },
  { title: "EnraMex", id: 29, image: EnraMex },
  { title: "Envia Lana", id: 30, image: Envialana },
  { title: "Inter Cambio", id: 31, image: InterCambio2 },
  { title: "Money Gram", id: 32, image: MoneyGram2 },
  { title: "Order Express", id: 33, image: OrderExpress },
  { title: "Orlandi Valuta", id: 34, image: OrlandiValuta },
  { title: "Ria", id: 35, image: Ria },
  { title: "World Remit", id: 38, image: WorldRemit },
  { title: "Western Onion", id: 37, image: WesternOnion },
  { title: "BARRI", id: 36, image: Barri },
];

const dataConfianza = [
  { title: "Inter-Cambio Express", id: 39, image: InterCambio },
  { title: "Money Gram", id: 40, image: MoneyGram },
  { title: "OMNEX", id: 41, image: OMnex },
  { title: "Sigue", id: 42, image: Sigue },
  { title: "Trans-Fast", id: 43, image: TransFast },
  { title: "UniTeller", id: 44, image: UniTeller },
  { title: "ViAmericas", id: 45, image: ViAmericas },
];

export default function Modal({ open, handleClose, selectedId }) {
  const getDataForId = () => {
    switch (selectedId) {
      case 1:
        return { title: "Intermex", data: dataIntermex };
      case 2:
        return { title: "AirPak", data: dataAirpak };
      case 3:
        return { title: "Confianza", data: dataConfianza };
      default:
        return { title: "Error!!", data: [] };
    }
  };

  const { title, data } = getDataForId();

  return (
    <Dialog open={open} onClose={handleClose}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h3" sx={{marginTop: 2 ,marginBottom: 1, color: "#062035" }}>
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {data.map((item, index) => (
          <Box
            key={index}
            sx={{
              marginBottom: 2,
              marginX: "9px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "8px",
              width: "26%",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={item.image}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  </Dialog>
  );
}

import React from "react";
import { Typography, Grid, Grow, Box, CardMedia, keyframes } from "@mui/material";
import FormContact from "../form/FormParts.jsx";
import imgContacto from "../img/Contact/Contact.webp";
import imgRibbonFigure from "../img/Contact/Circulos.webp";
import imgRibbon from "../img/Contact/imgRibbonContact.webp";
import ButtonArrow from "./ButtonArrow";
import Map from "./Map";
import GroupButton from "./GroupButtons.jsx";
//Para hacer las pulsaciones del texto
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export default function Contact() {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <ButtonArrow />
      <GroupButton/>
      <Grid
        container
        sx={{
          alignItems: "center", // Alinea el texto y la imagen en el centro verticalmente
          justifyContent: "flex-start", // Alinea la imagen a la izquierda
        }}
        style={{
          backgroundImage: `url(${imgRibbon})`, // Agrega la imagen de fondo
          backgroundSize: "cover", // Asegura que la imagen de fondo cubra todo el contenedor
          backgroundRepeat: "no-repeat", // Evita que la imagen de fondo se repita
        }}
      >
        <Grid item xs={4} sx={{ display: "flex" }}>
          <Grow in={true} timeout={{ enter: 4000, exit: 4000 }}>
            <img
              src={imgRibbonFigure}
              alt={imgRibbonFigure}
              style={{
                maxWidth: "45%",
                filter: "brightness(0) invert(1)", // Cambia el color de la imagen a blanco
                transform: "rotate(180deg)", // Gira la imagen 180 grados
              }}
              loading="lazy"
            />
          </Grow>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="h1"
            color="white"
            sx={{
              fontSize: {
                // Haciendo responsivo los textos
                xs: "2.4rem", sm: "3rem", md: "6rem",
                animation: `${pulse} 2s infinite`,
              },
            }}
          >
            CONTÁCTANOS
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="h5"
        align="center"
        color="#0C213D"
        sx={{ marginTop: 5 }}
      >
        !Visita Nuestras¡
      </Typography>
      <Typography
        variant="h3"
        align="center"
        color="#0C213D"
        sx={{ marginTop: 0, mb: 3 }}
      >
        <strong>SUCURSALES</strong>
      </Typography>

      <Box
        maxWidth="lg"
        sx={{
          margin: "auto", // Centra horizontalmente el componente del mapa
          marginBottom: "4rem",
          marginTop: "2rem",
          height: "100%",
        }}
      >
        <Map />
      </Box>

      <Box
        maxWidth="lg"
        sx={{ margin: "auto", marginBottom: "4rem", marginTop: "4rem" }}
      >
       <Grid container>
      <Grid item xs={12} sm={7} md={6} order={1}>
        <Box mr={5} ml={5}>
        <FormContact />
        </Box>
      </Grid>
      <Grid item xs={12} sm={5} md={6} order={2}>
        <CardMedia
          component="img"
          image={imgContacto}
          title="Encargada de captacion de Créditos  e inversiones."
          style={{ maxWidth: "100%", height: "auto", borderRadius: "9px" }} // Aplica un borde redondeado a la imagen
        />
      </Grid>
    </Grid>
      </Box>
    </Box>
  );
}

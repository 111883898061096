import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const staticData = [
  {
    cantidad: "$ 5,000",
    tasaInteres: "4.00%",
    tiempoDias: "30 días",
    validez: "Vigente",
    GatNominal: "4.07%",
    GatReal: "-1.57%",
    Montomaximo: "$ 499,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
  {
    cantidad: "$ 5,000",
    tasaInteres: "4.30%",
    tiempoDias: "60 días",
    validez: "Vigente",
    GatNominal: "4.38%",
    GatReal: "-1.27%",
    Montomaximo: "$ 499,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
  {
    cantidad: "$ 5,000",
    tasaInteres: "4.50%",
    tiempoDias: "90 días",
    validez: "Vigente",
    GatNominal: "4.58%",
    GatReal: "-1.07%",
    Montomaximo: "$ 499,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
  {
    cantidad: "$ 5,000",
    tasaInteres: "4.70%",
    tiempoDias: "180 días",
    validez: "Vigente",
    GatNominal: "4.76%",
    GatReal: "-0.87%",
    Montomaximo: "$ 499,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
  {
    cantidad: "$ 5,000",
    tasaInteres: "5.00%",
    tiempoDias: "360 días",
    validez: "Vigente",
    GatNominal: "5.06%",
    GatReal: "-0.57%",
    Montomaximo: "$ 499,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
  {
    cantidad: "$ 500,000",
    tasaInteres: "4.40%",
    tiempoDias: "30 días",
    validez: "Vigente",
    GatNominal: "4.49%",
    GatReal: "-1.17%",
    Montomaximo: "$ 4,999,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
  {
    cantidad: "$ 500,000",
    tasaInteres: "4.80%",
    tiempoDias: "60 días",
    validez: "Vigente",
    GatNominal: "4.90%",
    GatReal: "-0.77%",
    Montomaximo: "$ 4,999,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
  {
    cantidad: "$ 500,000",
    tasaInteres: "5.10%",
    tiempoDias: "90 días",
    validez: "Vigente",
    GatNominal: "5.20%",
    GatReal: "-0.47%",
    Montomaximo: "$ 4,999,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
  {
    cantidad: "$ 500,000",
    tasaInteres: "5.30%",
    tiempoDias: "180 días",
    validez: "Vigente",
    GatNominal: "5.37%",
    GatReal: "-0.27%",
    Montomaximo: "$ 4,999,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
  {
    cantidad: "$ 500,000",
    tasaInteres: "5.50%",
    tiempoDias: "360 días",
    validez: "Vigente",
    GatNominal: "5.58%",
    GatReal: "-0.07%",
    Montomaximo: "$ 4,999,999.99",
    Comisiones: "Este producto no genera comisiones",
  },
];

export default function InversionDetails({ plan }) {
  return (
    <>
      {/*Tab products credit Start*/}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          mb: "1rem",
          justifyContent: "center",
        }}
      >
        {/*Contend tabs start*/}
        <Box sx={{ overflowX: "auto" }}>
          <Typography
            component="span"
            variant="body1"
            paragraph
            sx={{ textAlign: "justify" }}
          >
            {plan.description}
          </Typography>

          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Monto Mínimo
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Monto Máximo
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    Plazos
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Tasa de interés anual
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    GAT Nominal
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>GAT Real</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staticData.map((data, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {data.cantidad}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.Montomaximo}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: "center" }}
                    >
                      {data.tiempoDias}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: "center" }}
                    >
                      {data.tasaInteres}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: "center" }}
                    >
                      {data.GatNominal}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.GatReal}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Box sx={{ width: "90%", mb: "3px", ml: "1rem" }}>
        <Typography component="span" variant="body2">
          <strong>Ganancia Anual Total Neta (GAT)</strong> Nominal “Antes de
          impuestos” “Para fines informativos y de comparación”. Fecha de
          cálculo 31 de julio de 2024.
        </Typography>
      </Box>
      <Box sx={{ width: "90%", mb: "1%", ml: "1rem" }}>
        <Typography component="span" variant="body2">
          <strong>Ganancia Anual Total Neta (GAT)</strong> Real “Antes de
          impuestos”. “La GAT Real es el rendimiento que obtendría después de
          descontar la inflación estimada”. “Para fines informativos y de
          comparación”. Fecha de cálculo 31 de julio de 2024.
        </Typography>
      </Box>
      <Box sx={{ width: "90%", mb: "2rem", ml: "1rem" }}>
        <Typography component="span" variant="body2">
          <strong> {plan.Comisiones}.</strong>
        </Typography>
      </Box>
      {/*End tabs details*/}
    </>
  );
}

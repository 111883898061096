import { useParams } from "react-router-dom";
import { savingsPlans } from "./Products";
import { Box, Container, Grid, Typography, keyframes } from "@mui/material";
import imgRibbon from "../img/ribbonDetaillsProduct.png";
import AhorroDetails from "./AhorroDetails";
import CreditoDetails from "./CreditoDetails";
import InversionDetails from "./InversionDetails";
import FormContact from "../form/FormParts.jsx";

//Animation title
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export default function DetailsProduct() {
  const { type, id } = useParams(); // Obtenemos tanto type como id de la URL
  const plan = savingsPlans.find(
    (plan) => plan.id === id && plan.type === type
  );

  // Asegurarse de que plan tenga tanto id como type
  if (!plan) {
    // Maneja el caso en el que no se encuentre ningún plan con el id y type proporcionados
    return (
      <Container maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          Producto no encontrado
        </Typography>
      </Container>
    );
  }

  // Para personalizar todo
  let detailsComponent;
  switch (plan.type) {
    case "AHORRO":
      //Detalles del componente AhorroDetails y pasarle el plan correspondiente
      detailsComponent = <AhorroDetails plan={plan} />;
      break;
    case "INVERSION":
      // Detalles del componente InversionDetails y pasarle el plan correspondiente
      detailsComponent = <InversionDetails plan={plan} />;
      break;
    case "CREDITO":
      // Detalles del componente CreditoDetails y pasarle el plan correspondiente
      detailsComponent = <CreditoDetails plan={plan} />;
      break;
    default:
      detailsComponent = <div>Tipo de producto no válido</div>;
  }

  return (
    <div>
      <Container maxWidth="xl">
        <Box
          sx={{
            my: 4,
            backgroundColor: "#0C213D",
            display: "flex",
            flexDirection: "column", // Añade esto
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            height: "6cm", // Añade esto
          }}
        >
          <img
            src={imgRibbon}
            alt="Ribbon"
            style={{
              width: "100%",
              height: "100%", // Cambia esto
              objectFit: "cover",
              position: "absolute",
            }}
          />
          <Typography
            variant="h1"
            align="center"
            gutterBottom
            color={"white"}
            sx={{
              fontSize: { xs: "2.8rem", sm: "4.2rem", md: "4.2rem" },
              animation: `${pulse} 2s infinite`,
            }}
          >
            {plan.type}
          </Typography>
        </Box>

        {/*Name Start*/}
        <Box sx={{ my: 4 }}>
          <Typography
            color={"#062035"}
            variant="h4"
            align="center"
            gutterBottom
          >
            {plan.name}
          </Typography>
        </Box>
        <Box>
          <Typography
            color={"#062035"}
            variant="body1"
            align="center"
            gutterBottom
          >
            {plan.descripcionProducto}
          </Typography>
          <Typography
            color={"#FB6823"}
            variant="h4"
            align="center"
            gutterBottom
            sx={{ marginBottom: 4, marginTop: 5 }}
          >
            {plan.TablaNombre}
          </Typography>
        </Box>
        {/*Name End*/}
        <Grid container spacing={0}>
          {/*Render detailscomponent*/}
          <Grid item xs={12} md={12} lg={8}>
            <Box sx={{ my: -1 }}>{detailsComponent}</Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4} sx={{ mb: 5 }}>
            {/*Form contact start*/}
            <FormContact />
            {/*Form contact end */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

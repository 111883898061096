import React from "react";
import Dialog from "@mui/material/Dialog";
import PDFDespachosCobranza from "../Documents/Despachos_de_Cobranza_CONDUSEF.pdf"
export default function DespachosCobranza({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <embed 
        src={PDFDespachosCobranza}
        type="application/pdf"
        style={{ width: '100%', height: '100vh' }}
      />
    </Dialog>
  );
}

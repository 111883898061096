import React from "react";
import { Container, Typography } from "@mui/material";
import ImgLogo from "../img/logo.webp";
import ButtonArrow from "../pages/ButtonArrow.jsx";
import GroupButtons from "../pages/GroupButtons.jsx";

export default function AvisoDePrivacidad() {
  const datosMediosParaEjercer = [
    "Deberá presentar una solicitud, mediante escrito libre en cualquiera de las sucursales de la Caja Solidaria La Huerta, SC DE AP DE RL DE CV, dicha solicitud deberá ser dirigida a la Caja Solidaria La Huerta, SC DE AP DE RL DE CV con copia para el responsable de la privacidad de sus datos personales. Su solicitud deberá contener la siguiente información, a su vez acompañar la documentación que a continuación se detalla",
    "El personal de la Caja Solidaria La Huerta, SC DE AP DE RL DE CV acusará de recibido su solicitud y en un plazo no mayor a 20 días hábiles a partir de la fecha en que se recibe, deberá remitirle una respuesta positiva o negativa.",
    "En caso de tener una respuesta positiva su solicitud la entrega de los datos personales será gratuita, debiendo cubrir el titular únicamente los gastos justificados de envío o con el costo de reproducción en copias u otros formatos, además la Caja Solidaria La Huerta, SC DE AP DE RL DE CV dispondrá de 20 días hábiles más, para hacer efectivos los ejercicios de derecho que solicite.",
  ];

  const datosMediosParaEjercerSubCampos = [
    "El nombre del titular, domicilio y teléfono para comunicarle la respuesta a su solicitud;",
    "Copia de la identificación oficial con fotografía (Credencial IFE, pasaporte, etc.) del Socio, Aspirante o Padre o tutor de algún menor de edad.",
    "La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados",
    "Deberá acreditar su personalidad como Socio, Aspirante o Padre o tutor de algún menor de edad (la personalidad se acredita con original de la libreta y/o con el original del certificado de aportación).",
  ];

  const datosFinesRecabamos = [
    "La revisión, realización y ejecución de todas y cada una de las operaciones pasivas y/o activas y la prestación de los servicios que la caja Solidaria La Huerta, SC DE AP DE RL DE CV ofrece, así como la celebración de los demás actos jurídicos que conforme a la Ley para Regular las Actividades de las Sociedades Cooperativas de Ahorro y Préstamo y las disposiciones emanadas de la misma, la Caja Solidaria La Huerta, SC DE AP DE RL DE CV puede realizar;",
    "La realización de estudios de mercado, así como la creación e implementación de procesos de análisis y estadísticos necesarios o convenientes, relacionados con dichas operaciones, servicios y actos;",
    "La promoción de productos, servicios, beneficios, promociones, sorteos y publicidad en general, todo esto ofrecido por o relacionado con la Caja Solidaria La Huerta, SC DE AP DE RL DE CV o terceros con quienes ésta Cooperativa tenga relación jurídica comercial;",
    "La atención de requerimientos y envío de información que por disposición legal sea obligación de la Caja Solidaria La Huerta, SC DE AP DE RL DE CV remitir, o en su caso, sea solicitada por parte de cualquier autoridad;",
    "La realización de consultas en Sociedades de Información Crediticia Nacionales o Extranjeras, que considere necesarias la Caja Solidaria La Huerta, SC DE AP DE RL DE CV;",
    "Para las investigaciones y revisiones en relación a cualquier queja o reclamación",
    "Para efectuar el procedimiento necesario para que la Caja Solidaria La Huerta, SC DE AP DE RL DE CV lleve a cabo el cobro judicial, extrajudicial, administrativo de los créditos que en su caso se contraten por parte de los socios.",
    "Para notificar cualquier acto, hecho, omisión o situación a los socios, aspirantes de socios, padres o tutores de socios o beneficiarios de éstos y la puesta en contacto para tratar cualquier tema relacionado con sus datos personales o con el presente aviso de privacidad.",
    "La realización de cualquier otra actividad necesaria para que la Caja Solidaria La Huerta, SC DE AP DE RL DE CV lleve a cabo su objeto social.",
  ];

  const datosProcedimientoParaLimitar = [
    "La persona que sea socio, aspirante o padre o tutor de algún menor de edad, puede dejar de recibir mensajes promocionales por teléfono fijo o celular, correo postal publicitario o correos electrónicos promocionales, siguiendo los siguientes pasos:",
    " Podrá presentar un escrito libre dirigido a la Caja Solidaria La Huerta, SC DE AP DE RL DE CV en cualquiera de nuestras sucursales donde un ejecutivo le acusará de recibido dicho escrito y será turnada al departamento de privacidad de datos personales, en el citado escrito se describirá qué tipo de mensaje promocional no requiere recibir.",
    "Deberá acreditar su carácter como socio, aspirante o padre o tutor de algún menor de edad.",
    "Una vez analizada la petición por parte del departamento de privacidad de datos personales, en un término de 20 días hábiles dejará de recibir los mensajes promocionales por teléfono fijo o celular, correo postal publicitario o correos electrónicos promocionales.",
  ];

  //Inicio Para pintar los datos del arreglo datosFinesRecabamos
  const listaDatos = datosFinesRecabamos.map((dato, index) => (
    <li key={index}>{dato}</li>
  ));

  //Inicio Para pintar los datos del arreglo datosProcedimientoparaLimitar
  const listaProcedimientos = datosProcedimientoParaLimitar.map(
    (procedimiento, index) => <li key={index}>{procedimiento}</li>
  );

  //Inicio Para pintar los datos del arreglo datosMediosParaEjercer
  const listaNumerada = datosMediosParaEjercer.map((opcion, index) => (
    <li key={index}>
      {opcion}
      {index === 0 && (
        <ol type="a">
          {datosMediosParaEjercerSubCampos.map((subcampo, subIndex) => (
            <li key={subIndex}>{subcampo}</li>
          ))}
        </ol>
      )}
    </li>
  ));

  return (
    <Container maxWidth="lg" sx={{ marginBottom: "5rem", background: "#F1F1F1" }}>
      <ButtonArrow />
      <GroupButtons />
      <Typography variant="h4" mt={4} mb={4} textAlign={"center"}>
        AVISO DE PRIVACIDAD
      </Typography>
      <Typography mb={2} mx={2} textAlign={"justify"}>
        <strong>La Caja Solidaria La Huerta, SC DE AP DE RL DE CV</strong> pone
        a su disposición, en todo momento, este aviso de privacidad en
        cualquiera de nuestras sucursales mediante anuncios visibles al público
        y en nuestra página de Internet:{" "}
        <a style={{ color: "blue" }} href="www.cajasolidarialahuerta.net">
          www.cajasolidarialahuerta.net
        </a>
      </Typography>

      <Typography mb={2} mx={2} textAlign={"justify"}>
        El presente aviso de privacidad se constituye para dar cumplimiento a lo
        ordenado por la Ley Federal de Protección de Datos Personales en
        Posesión de los Particulares, con éste aviso la Caja Solidaria La
        Huerta, SC DE AP DE RL DE CV cumple con la obligación legal derivada de
        la citada legislación, a su vez la Caja Solidaria La Huerta, SC DE AP DE
        RL DE CV manifiesta lo siguiente:
      </Typography>

      <Typography mx={2}>
        <u>
          Datos e identidad del responsable de la protección de sus datos
          personales:
        </u>
      </Typography>
      <Typography mx={2}>
        Denominación: Caja Solidaria La Huerta, SC DE AP DE RL DE CV.
      </Typography>
      <Typography mx={2}>
        Domicilio: Emiliano Zapata # 24, Colonia Centro, en La Huerta, Jalisco,
        C.P.: 48850.
      </Typography>
      <Typography mx={2}>
        Dirección en Internet:{" "}
        <a style={{ color: "blue" }} href="www.cajasolidarialahuerta.net">
          www.cajasolidarialahuerta.net
        </a>
        , en ésta página web podrá consultar el presente aviso de privacidad.
      </Typography>
      <Typography mx={2}>
        Teléfonos: 357-38-4-04-80, 357-38-4-14-20 y 357-38-4-17-83.
      </Typography>
      <Typography mx={2}>LADA SIN COSTO 018002252863.</Typography>
      <Typography mx={2} mb={2}>
        Departamento responsable de la privacidad: Lada sin costo: 018002252863
        o a los teléfonos: 357-38-4-04-80, 357-38-4-14-20 y 357-38-4-17-83
        extensión 114, correo electrónico:{" "}
        <a style={{ color: "blue" }} href="respprivacidad@cajasolidaria.net">
          respprivacidad@cajasolidaria.net
        </a>
      </Typography>

      <Typography mx={2}>
        <strong>
          <u>¿Qué datos personales obtenemos y de dónde?</u>
        </strong>
      </Typography>
      <Typography ml={5}>Los datos que obtenemos entre otros son:</Typography>
      <Typography mx={2} textAlign={"justify"}>
        Datos generales: Nombre, sexo, dirección, lugar de nacimiento, fecha de
        nacimiento, CURP, RFC, estado civil, régimen matrimonial, ocupación,
        actividad empresarial, ingresos y egresos, grado de estudio, profesión,
        dirección, tiempo de residencia, teléfonos para contactarlo, correo
        electrónico, escolaridad, persona que lo recomendó, referencias
        personales, nombre del cónyuge, dependientes económicos, nombre de
        beneficiarios, hijos, fotografía digitalizada, relación patrimonial
        (bienes muebles e inmuebles de los que sea propietario y/o frutos o
        utilidades económicas que generen los mismos), en su caso, nombre del
        padre o tutor, su domicilio y ocupación, nombre del cónyuge o persona
        con la que vive en unión libre y su domicilio u ocupación.
      </Typography>
      <Typography mx={2} textAlign={"justify"}>
        Datos laborales: Nombre de la empresa, domicilio y teléfono de la
        empresa donde trabaja, puesto que desempeña, antigüedad laboral.
      </Typography>
      <Typography mx={2} textAlign={"justify"} mb={2}>
        Datos laborales: Nombre de la empresa, domicilio y teléfono de la
        empresa donde trabaja, puesto que desempeña, antigüedad laboral.
      </Typography>

      <Typography mx={2} textAlign={"justify"} mb={2}>
        Son registrados y obtenidos sus datos personales por medio de la
        manifestación verbal que el titular de los datos nos proporciona, dicha
        información se captura en el sistema de cómputo de la Caja Solidaria La
        Huerta, SC DE AP DE RL DE CV, ésta manifestación verbal se da cuando
        alguna persona física, socio, aspirante a socio, aval, o padre o tutor
        de algún menor de edad, acude a las sucursales de Caja Solidaria La
        Huerta, SC DE AP DE RL DE CV a solicitar la apertura de una cuenta, de
        un crédito o algún servicio; en dicha visita, además nos proporciona
        fotocopias de los documentos oficiales que sirven de identificación
        (comprobante de domicilio, curp, rfc, acta de nacimiento, en su caso,
        acta de matrimonio y comprobante de ingresos económicos).
      </Typography>

      <Typography mx={2}>
        <strong>
          <u>¿Para qué fines recabamos y utilizamos sus datos personales?</u>
        </strong>
      </Typography>
      <Typography mx={2}>
        La Caja Solidaria La Huerta, SC DE AP DE RL DE CV harán tratamiento de
        los datos personales recabados, incluyendo especialmente
      </Typography>
      <ul>{listaDatos}</ul>

      <Typography mx={2}>
        <strong>
          <u textAlign={"justify"}>
            Procedimiento para limitar el uso o divulgación de sus datos
            personales:
          </u>
        </strong>
      </Typography>
      <ol>{listaProcedimientos}</ol>

      <Typography mx={2} mb={2} textAlign={"justify"}>
        En la página de internet{" "}
        <a style={{ color: "blue" }} href="www.cajasolidarialahuerta.net">
          www.cajasolidarialahuerta.net
        </a>{" "}
        no se generan archivos para almacenar su información personal.
      </Typography>

      <Typography mx={2}>
        <u textAlign={"justify"}>Datos personales sensibles:</u>
      </Typography>
      <Typography mx={2} textAlign={"justify"} mb={2}>
        La Caja Solidaria La Huerta, SC DE AP DE RL DE CV manifiesta que no
        recaba datos que afecten a la esfera más íntima de su titular, o cuya
        utilización indebida pueda dar origen a discriminación o conlleve un
        riesgo grave para éste, ni obtiene datos que puedan revelar aspectos
        como origen racial o étnico, estado de salud presente y futuro,
        información genética, creencias religiosas, filosóficas y morales,
        afiliación sindical, opiniones políticas, preferencia sexual.
      </Typography>

      <Typography mx={2} textAlign={"justify"}>
        <u textAlign={"justify"}>
          Medios para ejercer los derechos de acceso, rectificación, cancelación
          y oposición (ARCO):
        </u>
      </Typography>
      <Typography mx={2} textAlign={"justify"} mb={-1}>
        Usted tiene derecho de acceder a sus datos personales que poseemos y a
        los detalles del tratamiento de los mismos, así como a rectificarlos en
        caso de ser inexactos o incompletos; cancelarlos cuando considere que no
        se requieren para alguna de las finalidades señaladas en el presente
        aviso de privacidad, o estén siendo utilizados para finalidades no
        consentidas o haya finalizado la relación contractual o de servicio en
        apego a las normas, o bien, oponerse al tratamiento de los mismos para
        fines específicos. Los mecanismos que se han implementado para el
        ejercicio de dichos derechos son a través del siguiente procedimiento:
      </Typography>
      <ol style={{ textAlign: "justify", marginRight: "2em" }}>
        {listaNumerada}
      </ol>
      <Typography mb={3} mx={2} textAlign={"justify"}>
        Para mayor información, favor de comunicarse con el Departamento
        responsable de la privacidad: Lada sin costo: 018002252863 o a los
        teléfonos: 357-38-4-04-80, 357-38-4-14-20 y 357-38-4-17-83 extensión
        114, correo electrónico:{" "}
        <a style={{ color: "blue" }} href="respprivacidad@cajasolidaria.net">
          respprivacidad@cajasolidaria.net
        </a>{" "}
        o visitar nuestra página de Internet:{" "}
        <a style={{ color: "blue" }} href="cajasolidarialahuerta.net">
          cajasolidarialahuerta.net
        </a>
      </Typography>

      <Typography mx={2}>
        <strong>
          <u textAlign={"justify"}>
            Procedimiento para limitar el uso o divulgación de sus datos
            personales:
          </u>
        </strong>
      </Typography>
      <Typography mx={2} textAlign={"justify"} mb={3}>
        Le informamos que sus datos personales pueden ser transferidos y
        tratados dentro y fuera del país, por personas distintas a Caja
        Solidaria La Huerta, SC DE AP DE RL DE CV. En ese sentido, su
        información puede ser compartida solo con la finalidad de cumpl ir con
        el objetivo y servicios contratados por el titular, y dadas las
        relaciones legales y contractuales con que la Caja Solidaria La Huerta,
        SC DE AP DE RL DE CV lleve a cabo con autoridades y/o terceros con el
        objeto de satisfacerle el servicio solicitado y cumplir con la
        normatividad aplicable. El compromiso de Caja Solidaria La Huerta, SC DE
        AP DE RL DE CV va enfocado a no transferir su información personal a
        terceros sin su consentimiento, salvo las excepciones previstas en el
        artículo 37 de la Ley Federal de Protección de Datos Personales en
        Posesión de los Particulares, por tal motivo el titular manifiesta desde
        éstos momentos su consentimiento expreso en el sentido de que Caja
        Solidaria La Huerta, SC DE AP DE RL DE CV pueda transferir sus datos
        personales en los términos y con el objeto descrito en el presente aviso
        de privacidad.
      </Typography>

      <Typography mx={2}>
        <strong>
          <u textAlign={"justify"}>Cambios al presente aviso de privacidad:</u>
        </strong>
      </Typography>
      <Typography mx={2} textAlign={"justify"} mb={3}>
        Caja Solidaria La Huerta, SC DE AP DE RL DE CV se reserva el derecho de
        llevar a cabo modificaciones y/o actualizaciones al presente aviso de
        privacidad, para la atención de reformas legales o jurisprudenciales, de
        políticas y procedimientos internos, de prestación u ofrecimiento de
        nuestros servicios o productos.
      </Typography>

      <Typography ml={5} textAlign={"justify"} mb={1}>
        Cualquier cambio al presente le será informado a través de los
        siguientes medios:
      </Typography>
      <Typography ml={5} textAlign={"justify"}>
        a) Aviso en nuestras sucursales.
      </Typography>
      <Typography ml={5} mb={3} textAlign={"justify"}>
        b) A través de nuestro sitio web:{" "}
        <a style={{ color: "blue" }} href="cajasolidarialahuerta.net">
          cajasolidarialahuerta.net
        </a>
      </Typography>

      <Typography ml={5} mb={25} mx={2} textAlign={"justify"}>
        En éste sentido si usted considera que la Caja Solidaria La Huerta, SC
        DE AP DE RL DE CV, sus empleados o directivos han violado el presente
        aviso de privacidad o se hayan vulnerado sus derechos de privacidad
        consagrados en la Ley Federal de Protección de Datos Personales en
        Posesión de los Particulares, le sugerimos se informe el procedimiento a
        seguir en el sitio web:{" "}
        <a style={{ color: "blue" }} href="www.ifai.org.mx">
          www.ifai.org.mx
        </a>
      </Typography>

      <div style={{ textAlign: "center" }}>
        <hr
          style={{
            width: "60%",
            margin: "0 auto",
            marginLeft: "21%",
            marginBottom: "1%",
          }}
        />
        <Typography ml={4} mb={5} textAlign={"center"}>
          <strong>NOMBRE Y FIRMA DEL SOCIO</strong>
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "30px",
          marginLeft: "5%",
        }}
      >
        <img src={ImgLogo} alt="Logo" style={{ width: "25%", marginBottom: "4%" }} />
      </div>
    </Container>
  );
}

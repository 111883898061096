import React from "react";
import {
  Box,
  Container,
  Tabs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Grid,
  TableRow,
  keyframes,
  TableContainer,
  Paper,
} from "@mui/material";
import imgRibbon from "../img/ribbonCosts.webp";
import Tab from "@mui/material/Tab";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
//Boton para subir la pantalla
import ButtonArrow from "../pages/ButtonArrow";
import GroupButton from "../pages/GroupButtons";
import { savingsPlans } from "../pages/Products";
import { staticData } from "../pages/InversionDetails";
import ReplyIcon from "@mui/icons-material/Reply";
//Para hacer las pulsaciones del texto principal
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

function TabPanel({ value, index, children }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// Función para generar el enlace a la ruta /detailsProduct/AHORRO/11
const generateLink = (id) => `#/DetailsProduct/AHORRO/${id}?tab=3`;
export default function Partherns() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ahorroPlans = savingsPlans.filter((plan) => plan.type === "AHORRO");
  const creditoPlans = savingsPlans.filter((plan) => plan.type === "CREDITO");
  return (
    <Container maxWidth="lg">
      <ButtonArrow />
      <GroupButton />
      {/*Ribbon Start*/}
      <Box
        sx={{
          my: 4,
          backgroundColor: "#0C213D",
          display: "flex",
          flexDirection: "column", // Añade esto
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "6cm", // Añade esto
        }}
      >
        <img
          src={imgRibbon}
          alt="Ribbon"
          style={{
            width: "100%",
            height: "100%", // Cambia esto
            objectFit: "cover",
            position: "absolute",
          }}
          loading="lazy"
        />
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          color={"white"}
          sx={{
            fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem" },
            animation: `${pulse} 2s infinite`,
          }}
        >
          COSTOS Y COMISIONES
        </Typography>
      </Box>
      {/*Ribbon Start*/}

      {/*Socios Start*/}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        color={"#0C213D"}
        sx={{
          top: { xs: "32%", sm: "32%", lg: "37%" },
          left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
        }}
      >
        DE NUESTROS PRODUCTOS FINANCIEROS
      </Typography>

      {/*Socios End*/}

      {/*Socios tabs start*/}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Tab partners"
            centered
          >
            <Tab
              icon={<MonetizationOnIcon />}
              label="AHORRO"
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  marginRight: 1,
                },
              }}
            />
            <Tab
              icon={<LocalAtmIcon />}
              label="CRÉDITO"
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  marginRight: 1,
                },
              }}
            />
            <Tab
              icon={<AutoGraphIcon />}
              label="INVERSIÓN"
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  marginRight: 1,
                },
              }}
            />
          </Tabs>
        </Box>
        {/*Contend tabs start*/}
        <TabPanel value={value} index={0}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"#0C213D"}
            sx={{
              top: { xs: "32%", sm: "32%", lg: "37%" },
              left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
            }}
          >
            AHORRO
          </Typography>

          <Grid container spacing={2} mt={3}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#0C213D" }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      NUESTROS PRODUCTOS DE AHORRO
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", color: "white" }}>
                      NOMBRE
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", color: "white" }}>
                      COMISIONES
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ahorroPlans.map((plan) => (
                    <TableRow key={plan.id}>
                      <TableCell align="center">
                        <img
                          src={plan.image}
                          alt={plan.name}
                          style={{ maxWidth: "200px" }}
                          loading="lazy"
                        />
                      </TableCell>
                      <TableCell>{plan.name}</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        {plan.name === "TARJETA DE DEBITO" ? (
                          <a href={generateLink(plan.id)}>
                            {plan.Comisiones} <ReplyIcon fontSize="small" />
                          </a>
                        ) : (
                          plan.Comisiones
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"#FB6823"}
            sx={{
              top: { xs: "32%", sm: "32%", lg: "37%" },
              left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
            }}
          >
            CRÉDITO
          </Typography>
          <Grid container spacing={2} mt={3}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#0C213D" }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      NUESTROS PRODUCTOS DE CRÉDITO
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", color: "white" }}>
                      NOMBRE
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", color: "white" }}>
                      COSTOS
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", color: "white" }}>
                      COMISIONES
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditoPlans.map((plan) => (
                    <TableRow key={plan.id}>
                      <TableCell align="center">
                        <img
                          src={plan.image}
                          alt={plan.name}
                          style={{ maxWidth: "200px" }}
                           loading="lazy"
                        />
                      </TableCell>
                      <TableCell>{plan.name}</TableCell>
                      <TableCell>{plan.TasaOrdinaria}</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        {plan.Comisiones}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color={"#0C213D"}
            sx={{
              top: { xs: "32%", sm: "32%", lg: "37%" },
              left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
            }}
          >
            INVERSIÓN
          </Typography>

          <Grid container spacing={2} mt={3}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#0C213D" }}>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold", color: "white" }}>
                      MONTO MÍNIMO
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", color: "white" }}>
                      MONTO MÁXIMO
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      TASA DE INTERÉS ANUAL
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", color: "white" }}>
                      PLAZOS
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", color: "white" }}>
                      COMISIONES
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staticData.map((plan) => (
                    <TableRow key={plan.id}>
                      <TableCell>{plan.cantidad}</TableCell>
                      <TableCell>{plan.Montomaximo}</TableCell>
                      <TableCell align="center">{plan.tasaInteres}</TableCell>
                      <TableCell>{plan.tiempoDias}</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        {plan.Comisiones}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </TabPanel>
      </Box>
    </Container>
  );
}
